import { useEffect, useState } from 'react';
// material-ui
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';

// assets
import { requestUrl } from 'utils/Helpers';
import axiosServices from 'utils/axiosServices';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import Loader from 'ui-component/Loader';
import langString from 'utils/langString';
import FormValidators from 'utils/FormValidators';

type ProfileEditPropsType = {
    profile: any;
    updated: () => void;
};
export default function ProfileEdit({ profile, updated }: ProfileEditPropsType) {
    const [loading, setLoading] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [gender, setGender] = useState('Male');
    const [photo, setPhoto] = useState<any>(null);
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notEdited, setNotEdited] = useState(true);
    const dispatch = useDispatch();

    const updateProfile = async (event: any) => {
        event.preventDefault();
        const allErrors = FormValidators(['firstname', 'lastname', 'email'], [firstname, lastname, email]);
        if (allErrors.valid) {
            setIsSubmitting(true);
            setErrors(null);
            setLoading(true);
            const formData = new FormData();
            formData.append('firstname', firstname);
            formData.append('lastname', lastname);
            formData.append('email', email);
            formData.append('gender', gender);
            formData.append('profile_pic', photo);
            try {
                const response = await axiosServices.post(requestUrl('user', 'edit_user_profile'), formData);
                if (response.data.success) {
                    updated();
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: response.data.msg,
                        variant: 'alert',
                        alertSeverity: 'success'
                    });
                } else {
                    dispatch({
                        type: SNACKBAR_OPEN,
                        open: true,
                        message: response.data.msg,
                        variant: 'alert',
                        alertSeverity: 'error'
                    });
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                dispatch({
                    type: SNACKBAR_OPEN,
                    open: true,
                    message: langString('servererror'),
                    variant: 'alert',
                    alertSeverity: 'error'
                });
            }
        } else {
            setErrors(allErrors);
        }
        setIsSubmitting(false);
        setLoading(false);
    };

    useEffect(() => {
        if (profile) {
            setFirstname(profile.firstname);
            setLastname(profile.lastname);
            setEmail(profile.email);
            setGender(profile.gender);
            setPhoto(profile.profile_pic);
        }
    }, [profile]);

    return (
        <Grid container direction="column" spacing={gridSpacing}>
            {loading && <Loader />}
            {profile && (
                <Grid item xs={12}>
                    <SubCard title={langString('editprofile')}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <form noValidate onSubmit={updateProfile} method="post" encType="multipart/form-data">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label={langString('firstname')}
                                                id="firstname"
                                                margin="normal"
                                                name="firstname"
                                                type="text"
                                                onChange={(event: any) => {
                                                    setFirstname(event.target.value);
                                                    setNotEdited(false);
                                                }}
                                                value={firstname}
                                                error={errors && Boolean(errors.firstname?.required)}
                                                helperText={errors && errors.firstname?.message}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label={langString('lastname')}
                                                id="lastname"
                                                margin="normal"
                                                name="lastname"
                                                type="text"
                                                onChange={(event: any) => {
                                                    setLastname(event.target.value);
                                                    setNotEdited(false);
                                                }}
                                                value={lastname}
                                                error={errors && Boolean(errors.lastname?.required)}
                                                helperText={errors && errors.lastname?.message}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                fullWidth
                                                label={langString('email')}
                                                id="email"
                                                margin="normal"
                                                name="email"
                                                type="text"
                                                onChange={(event: any) => {
                                                    setEmail(event.target.value);
                                                    setNotEdited(false);
                                                }}
                                                value={email}
                                                error={errors && Boolean(errors.email?.required)}
                                                helperText={errors && errors.email?.message}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <Typography sx={{ padding: 1 }}>{langString('gender')}</Typography>
                                                <RadioGroup
                                                    row
                                                    id="gender"
                                                    aria-label="gender"
                                                    value={gender}
                                                    defaultValue={gender}
                                                    onChange={(event: any) => {
                                                        setGender(event.target.value);
                                                        setNotEdited(false);
                                                    }}
                                                    name="gender"
                                                >
                                                    <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                                    <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <FormControl fullWidth>
                                                <Typography sx={{ padding: 1 }}>{langString('photo')}</Typography>
                                                <input
                                                    style={{ padding: 5, border: '1px solid #EEE' }}
                                                    accept="image}/*"
                                                    type="file"
                                                    id="profilePhoto"
                                                    onChange={(event: any) => {
                                                        setPhoto(event?.target?.files[0]);
                                                        setNotEdited(false);
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ mt: 4, textAlign: 'right' }}>
                                        <Button
                                            disableElevation
                                            disabled={isSubmitting || notEdited}
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            Save
                                        </Button>
                                    </Box>
                                </form>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            )}
        </Grid>
    );
}
