import React from 'react';
import { Grid, Box } from '@mui/material';
import { DataGrid, GridAlignment, GridValueGetterParams } from '@mui/x-data-grid';
import ConfirmButton from 'components/common/ConfirmButton';
import langString from 'utils/langString';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export type SelectColumnType = {
    field: string;
    headerName: string;
    flex?: number;
    align?: GridAlignment;
    headerAlign?: GridAlignment;
    filterable?: boolean;
    sortable?: boolean;
    hideable?: boolean;
    disableColumnMenu?: boolean;
    renderCell?: (params: GridValueGetterParams) => React.ReactNode;
};

type BatchTableProps = {
    rows: any[];
    count: number;
    page: number;
    rowsPerPage: number;
    setPage: (value: number) => void;
    setRowsPerPage: (value: number) => void;
    selectedStudents: number[];
    setSelectedStudents: (value: number[]) => void;
    addToBatch: (value: number) => void;
    batchName: string;
    loading?: boolean;
};

export default function BatchTable({
    rows,
    count,
    selectedStudents,
    setSelectedStudents,
    addToBatch,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    batchName,
    loading
}: BatchTableProps) {
    const onRowsSelectionHandler = (ids: any) => {
        const selectedRowsData = ids.map((id: any) => parseInt(id, 10));
        setSelectedStudents(selectedRowsData);
    };

    const onPageSizeChange = (newPageSize: number) => {
        if (newPageSize) {
            setRowsPerPage(newPageSize);
            setTimeout(() => {
                // setTimeout function to delay the call to setPage(0) by a small amount of time. This delay allows React to update the page state before the new value is applied.
                setPage(0);
            }, 100);
        }
    };

    const StudentColumns: SelectColumnType[] = [
        {
            field: 'SL',
            headerName: 'SL.',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            hideable: true,
            renderCell: (index: any) => page * rowsPerPage + index.api.getRowIndex(index.row.id) + 1
        },
        {
            field: 'fullname',
            headerName: langString('name'),
            align: 'center',
            headerAlign: 'center',
            flex: 3
        },
        {
            field: 'phone',
            headerName: langString('phone'),
            align: 'center',
            headerAlign: 'center',
            flex: 3
        },
        {
            field: 'action',
            headerName: 'Add',
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (item: any) => (
                <>
                    <ConfirmButton
                        icon={<AddCircleIcon color="primary" />}
                        title={langString('add')}
                        subTitle={`Add ${item.row.fullname} to batch ${batchName}`}
                        confirmed={() => addToBatch(item.id)}
                    />
                </>
            )
        }
    ];

    return (
        <Grid container>
            <Grid item sm={12} width="100%">
                <Box sx={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        autoHeight
                        rows={rows}
                        columns={StudentColumns}
                        paginationMode="server"
                        onPageSizeChange={(event: number) => onPageSizeChange(event)}
                        rowCount={count}
                        loading={loading}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        pageSize={rowsPerPage}
                        onPageChange={setPage}
                        page={page}
                        checkboxSelection
                        onSelectionModelChange={(ids: any) => onRowsSelectionHandler(ids)}
                        disableSelectionOnClick
                    />
                </Box>
            </Grid>
        </Grid>
    );
}
