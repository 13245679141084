import { useState, useEffect } from 'react';
import { Box, FormControl, Grid, Typography, InputLabel, Select, MenuItem, Button } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import axiosServices from 'utils/axiosServices';
import { barChartData_1, makeFormData, marksColor, requestUrl, sortedPairedDataList } from 'utils/Helpers';
import langString from 'utils/langString';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { BarOptions, BarPairedSeries } from 'utils/ChartTypes';

export default function DashboardChartTopicReport() {
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [options, setOptions] = useState<BarOptions | null>(null);
    const [series, setSeries] = useState<BarPairedSeries | null>(null);
    const [reportBy, setReportBy] = useState('correctness');
    const [sortBy, setSortBy] = useState('def');

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('dashboard', 'topic_wise_report'),
                    makeFormData([{ key: 'course_id', value: course?.id }])
                );
                if (response.data.success) {
                    if (response.data?.response && response.data?.response.length) {
                        const labels = response.data.response.map((item: any) => item.name);
                        const dataList: [] = response.data.response.map((item: any) =>
                            reportBy === 'correctness' ? item.report.correct_percentage : item.report.right_answer
                        );
                        const toBeSort = barChartData_1(labels, dataList);
                        const sortedPairedData: object[] = sortedPairedDataList(toBeSort, sortBy);
                        setSeries([{ name: reportBy === 'correctness' ? '%' : 'Correct', data: sortedPairedData }]);
                        const barColors: string[] = sortedPairedData.map((data: any) => marksColor(parseInt(data.y, 10)));
                        setOptions({
                            chart: {
                                type: 'bar',
                                height: 350
                            },
                            colors: barColors,
                            plotOptions: {
                                bar: {
                                    distributed: true,
                                    borderRadius: 4,
                                    horizontal: true
                                }
                            },
                            dataLabels: {
                                enabled: false
                            },
                            xaxis: {
                                type: 'category'
                            },
                            legend: {
                                position: 'right',
                                offsetY: 40
                            }
                        });
                    } else {
                        setOptions(null);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (course.id) {
            init();
        }
    }, [course.id, reportBy, sortBy]);

    return (
        course && (
            <Box sx={{ marginY: 3, borderBottom: '1px solid #DDD', padding: 2 }}>
                <Grid container spacing={2}>
                    <Grid item sm={6}>
                        <Box sx={{ padding: 1.5, border: '1px solid #e67e22', borderRadius: '10px', textAlign: 'center' }}>
                            <Typography variant="h5" sx={{ color: '#e67e22' }}>
                                Topic Comparison of {course?.name}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={3} />
                    <Grid item sm={3}>
                        <FormControl sx={{ paddingRight: 1, minWidth: '150px' }}>
                            <InputLabel id="sort-select-label">{langString('sort')}</InputLabel>
                            <Select
                                size="small"
                                name="sorts"
                                labelId="sort-select-label"
                                id="sort-select"
                                value={sortBy}
                                label={langString('sort')}
                                onChange={(event: any) => setSortBy(event.target.value)}
                            >
                                <MenuItem value="def">Default</MenuItem>
                                <MenuItem value="alp">Alphabetical</MenuItem>
                                <MenuItem value="asc">Ascending</MenuItem>
                                <MenuItem value="desc">Descending</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginY: 4 }}>
                    <Grid item xs={12}>
                        <Grid container spacing={8} justifyContent="center" alignItems="center">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: reportBy === 'correctness' ? '#00807e' : '#cfcfcf' }}
                                    onClick={() => setReportBy('correctness')}
                                >
                                    Report by correctness %
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    sx={{ backgroundColor: reportBy === 'question' ? '#00807e' : '#cfcfcf' }}
                                    onClick={() => setReportBy('question')}
                                >
                                    Report by number of question
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12}>
                        {options && series ? (
                            <ReactApexChart options={options} series={series} type="bar" height="auto" />
                        ) : (
                            <Typography variant="h3" sx={{ textAlign: 'center' }}>
                                {langString('nodatafound')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        )
    );
}
