import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { checkNullInfo, makeFormData, requestUrl } from 'utils/Helpers';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { useParams } from 'react-router-dom';
import EnhancedTable from 'components/EnhancedTable';
import { HeadCellType } from 'components/EnhancedTable/Header';

export default function ProfileReport() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [student, setStudent] = useState<any>();
    const [quizes, setQuizes] = useState<any[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const { studentID } = useParams();

    const QuizColumns: HeadCellType[] = [
        { disablePadding: true, label: langString('quizname'), id: 'quizname', numeric: false },
        { disablePadding: true, label: langString('earnedmark'), id: 'earnedMark', numeric: true },
        { disablePadding: true, label: langString('totalmark'), id: 'totalmark', numeric: true },
        { disablePadding: true, label: langString('highestmark'), id: 'highestMark', numeric: true },
        { disablePadding: true, label: langString('averagemark'), id: 'averageMark', numeric: true },
        { disablePadding: true, label: langString('quizgrade'), id: 'quizgrade', numeric: true },
        { disablePadding: true, label: langString('gradepercent'), id: 'gradePercent', numeric: true }
    ];

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'student_report'),
                    makeFormData([
                        { key: 'user_id', value: studentID || 0 },
                        { key: 'course_id', value: course?.id }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setQuizes(response.data.response);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course && studentID) {
            init();
        } else {
            setSnackbar({ open: true, message: langString('coursenotselected') });
        }
    }, [studentID, course]);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('user', 'get_user_by_id'),
                    makeFormData([{ key: 'user_id', value: studentID || 0 }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setStudent(response.data.response);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (studentID) {
            init();
        } else {
            setSnackbar({ open: true, message: langString('invaliddata') });
        }
    }, [studentID]);

    return (
        <Wrapper
            title={`${langString('studentreport')} : ${checkNullInfo(student?.firstname)} ${checkNullInfo(student?.lastname)}`}
            icon={<AssessmentIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
            backLink="/student-reports"
        >
            <Grid container>
                <Grid item sm={12}>
                    <EnhancedTable idField="quizid" rows={quizes} headerCells={QuizColumns} />
                </Grid>
            </Grid>
        </Wrapper>
    );
}
