import { useState, useEffect } from 'react';
// material-ui
import { Typography, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import langString from 'utils/langString';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import axiosServices from 'utils/axiosServices';
import { requestUrl, makeFormData } from 'utils/Helpers';
import dayjs from 'dayjs';
import Wrapper from 'components/Card/Wrapper';
import QuizIcon from '@mui/icons-material/Quiz';
import ConfirmButton from 'components/common/ConfirmButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function QuizPublishingReports() {
    const [publishedBatches, setPublishedBatches] = useState([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const deleteQuiz = async (id: number) => {
        setLoading(true);
        try {
            const response = await axiosServices.post(requestUrl('ace_exam', 'delete_quiz'), makeFormData([{ key: 'quiz_id', value: id }]));
            if (response.data.success) {
                if (response.data?.response) {
                    getPublishedQuizes();
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            // setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    const PublishQuizColumns: ColumnType[] = [
        {
            header: langString('timepublished'),
            accessor: 'timepublished',
            content: (item: any) => <Typography>{dayjs(parseInt(item.timepublished, 10) * 1000).format('LL')}</Typography>
        },
        {
            header: langString('name'),
            accessor: 'name',
            content: (item: any) => <Typography>{item.name}</Typography>
        },
        {
            header: langString('batch'),
            accessor: 'batchname',
            content: (item: any) => <Typography>{item.batchname}</Typography>
        },
        // {
        //     header: langString('participants'),
        //     accessor: 'totalparticipants',
        //     content: (item: any) => (
        //         <Typography variant="h4">
        //             <Chip label={item.totalparticipants} />
        //         </Typography>
        //     )
        // },
        {
            header: langString('status'),
            accessor: 'status',
            content: (item: any) =>
                parseInt(item.status, 10) === 1 ? (
                    <Typography variant="h4" gutterBottom>
                        <Chip label="Active" color="success" />
                    </Typography>
                ) : (
                    <Typography variant="h4" gutterBottom>
                        <Chip label="Pending" color="error" />
                    </Typography>
                )
        },
        {
            header: 'Action',
            accessor: 'action',
            content: (item: any) => (
                <>
                    <ConfirmButton
                        color="error"
                        icon={<DeleteForeverIcon />}
                        subTitle={`Delete quiz ${item.name}`}
                        confirmed={() => deleteQuiz(item.id)}
                    />
                    {/* <EditBatch
                        batchID={item.id}
                        batchName={item.name}
                        batchKey={item.enrolmentkey}
                        batchCapacity={item.max_students}
                        batchSchedules={item.schedules?.length ? item.schedules : ['Sunday']}
                        refreshList={() => refreshList()}
                        setSnackbar={(value: SnackBarType) => setSnackbar(value)}
                    /> */}
                </>
            )
        }
    ];

    const getPublishedQuizes = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'get_published_quizes'),
                makeFormData([
                    { key: 'course_id', value: course?.id },
                    { key: 'rows_per_page', value: rowsPerPage },
                    { key: 'page_no', value: page }
                ])
            );
            if (response.data.success) {
                if (response.data?.response) {
                    setPublishedBatches(response.data.response);
                    setCount(100);
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };
    useEffect(() => {
        getPublishedQuizes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, page, rowsPerPage]);
    return (
        <>
            <Wrapper title={langString('allpublications')} icon={<QuizIcon />} loading={loading}>
                <Grid container>
                    <Grid item sm={12} md={12}>
                        {!loading && (
                            <BasicDataTable
                                rows={publishedBatches}
                                columns={PublishQuizColumns}
                                showSL
                                count={count}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                setPage={(value: number) => setPage(value)}
                                setRowsPerPage={(value: number) => setRowsPerPage(value)}
                            />
                        )}
                    </Grid>
                </Grid>
            </Wrapper>
        </>
    );
}
