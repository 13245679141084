import { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

type DataGridProps = {
    columns: any[];
    rows: any[];
    checkAll?: boolean;
    loading?: boolean;
    paginationMode?: boolean;
};

export default function DataGridTable({ columns, rows, checkAll, loading, paginationMode }: DataGridProps) {
    const count = rows.length;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const onPageSizeChange = (newPageSize: number) => {
        if (newPageSize) {
            setRowsPerPage(newPageSize);
            setTimeout(() => {
                // setTimeout function to delay the call to setPage(0) by a small amount of time. This delay allows React to update the page state before the new value is applied.
                setPage(0);
            }, 0);
        }
    };

    return (
        <Box sx={{ height: '100%', width: 'auto' }}>
            <DataGrid
                autoHeight
                rowHeight={100}
                rows={rows}
                columns={columns}
                paginationMode={paginationMode ? 'server' : 'client'}
                pageSize={rowsPerPage}
                onPageChange={setPage}
                page={page}
                onPageSizeChange={(event: number) => onPageSizeChange(event)}
                rowCount={count}
                loading={loading}
                rowsPerPageOptions={[10, 25, 50, 100]}
                checkboxSelection={checkAll}
                disableSelectionOnClick
            />
        </Box>
    );
}
