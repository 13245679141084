import { useEffect, useState } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { makeFormData, requestUrl } from 'utils/Helpers';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { useNavigate } from 'react-router-dom';

export default function StudentReports() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [students, setStudents] = useState<any[]>([]);
    const [batches, setBatches] = useState<any[]>([]);
    const [batchID, setBatchID] = useState(0);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    const StudentColumns: ColumnType[] = [
        {
            header: langString('name'),
            accessor: 'name'
        },
        {
            header: langString('phone'),
            accessor: 'phone'
        },
        {
            header: 'View',
            accessor: 'view',
            content: (item: any) => (
                <Button color="primary" size="small" variant="contained" onClick={() => navigate(`/student-report/${item.userid}`)}>
                    View
                </Button>
            )
        }
    ];

    useEffect(() => {
        if (course && course.batches) {
            setBatches(course.batches);
            if (batches) {
                setBatchID(0);
            } else {
                setSnackbar({ open: true, message: langString('nobatchavailable') });
            }
        }
    }, [course, batches]);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_batch_students'),
                    makeFormData([
                        { key: 'course_id', value: course?.id },
                        { key: 'batch_id', value: batchID },
                        { key: 'search', value: search },
                        { key: 'rows_per_page', value: rowsPerPage },
                        { key: 'page_no', value: page }
                    ])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response?.data) {
                        setStudents(response.data.response?.data);
                        setCount(response.data.response?.totalCount);
                    }
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        if (course) {
            init();
        }
    }, [course, batchID, search, rowsPerPage, page]);

    return (
        <Wrapper
            title={langString('studentreports')}
            icon={<GroupIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
            // backLink="/batch"
        >
            <Grid container sx={{ marginBottom: 2 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth sx={{ paddingRight: 1 }}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            size="small"
                            name="batchs"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batchID}
                            label={langString('batch')}
                            onChange={(event: any) => setBatchID(event.target.value)}
                        >
                            <MenuItem value={0}>{langString('all')}</MenuItem>
                            {batches.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        id="outlined-search"
                        value={search}
                        name="search"
                        size="small"
                        onChange={(event: any) => setSearch(event.target.value)}
                        label={langString('search')}
                        placeholder={langString('search')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={12}>
                    <BasicDataTable
                        rows={students}
                        columns={StudentColumns}
                        showSL
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={(value: number) => setPage(value)}
                        setRowsPerPage={(value: number) => setRowsPerPage(value)}
                    />
                </Grid>
            </Grid>
        </Wrapper>
    );
}
