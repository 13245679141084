export type PieOptions = {
    chart: {
        width: number;
        type: 'pie';
        toolbar?: any;
        events?: any;
    };
    plotOptions: {
        pie: {
            offsetX?: any;
            offsetY?: any;
            distributed: boolean;
            expandOnClick: boolean;
            dataLabels?: {
                offset?: number;
            };
        };
    };
    labels: string[];
    colors?: string[];
    responsive: [
        {
            breakpoint: number;
            options: {
                chart: {
                    width: number;
                };
                legend: {
                    position: string;
                };
            };
        }
    ];
    markers?: any;
};

export type BarSeries = {
    name?: string;
    data: number[];
};

export type BarPairedSeries = [
    {
        name?: string;
        data: object[];
    } | null
];

export type BarOptions = {
    chart: {
        type: 'bar';
        height: number | string;
        width?: number | string;
        toolbar?: any;
        events?: any;
    };
    plotOptions?: {
        bar: {
            distributed: boolean;
            borderRadius: number;
            horizontal: boolean;
            columnWidth?: string;
            barHeight?: string;
        };
    };
    title?: {
        text: string;
        align: 'left' | 'right' | 'center' | undefined;
    };
    colors?: string[];
    dataLabels?: {
        enabled: boolean;
    };
    xaxis: {
        type?: 'numeric' | 'category' | 'datetime' | undefined;
        categories?: string[];
    };
    legend?: {
        position: 'right' | 'left' | 'bottom' | 'top';
        offsetY: number;
    };
};

export type LineOptions = {
    chart: {
        height: number | string;
        type: 'line';
        zoom: {
            enabled: boolean;
        };
        toolbar?: any;
    };
    dataLabels: {
        enabled: boolean;
    };
    title: {
        text: string;
        align: 'left' | 'right' | 'center' | undefined;
    };
    stroke: {
        curve: 'straight';
    };
    grid?: {
        row: {
            colors: string[]; // takes an array which will be repeated on columns
            opacity: number;
        };
    };
    xaxis: {
        categories: string[];
    };
    markers?: any;
};

export const GradeLevels = ['0-20', '21-40', '41-60', '61-80', '81-100'];
export const pieChartColor = ['#e74c3c', '#e67e22', '#f1c40f', '#3498db', '#1abc9c'];
