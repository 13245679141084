import React, { useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    Typography
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import langString from 'utils/langString';
import InfoIcon from '@mui/icons-material/Info';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import axiosServices from 'utils/axiosServices';
import { requestUrl, makeFormData } from 'utils/Helpers';
import dayjs from 'dayjs';

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
));

const PublishQuizColumns: ColumnType[] = [
    {
        header: langString('timepublished'),
        accessor: 'timepublished',
        content: (item: any) => <Typography>{dayjs(parseInt(item.timepublished, 10) * 1000).format('LL')}</Typography>
    },
    {
        header: langString('name'),
        accessor: 'name',
        content: (item: any) => <Typography>{item.name}</Typography>
    },
    {
        header: langString('batch'),
        accessor: 'batchname',
        content: (item: any) => <Typography>{item.batchname}</Typography>
    },
    {
        header: langString('participants'),
        accessor: 'totalparticipants',
        content: (item: any) => (
            <Typography variant="h4">
                <Chip label={item.totalparticipants} />
            </Typography>
        )
    },
    {
        header: langString('status'),
        accessor: 'status',
        content: (item: any) =>
            parseInt(item.status, 10) === 1 ? (
                <Typography variant="h4" gutterBottom>
                    <Chip label="Active" color="success" />
                </Typography>
            ) : (
                <Typography variant="h4" gutterBottom>
                    <Chip label="Pending" color="error" />
                </Typography>
            )
    }
    // {
    //     header: 'Action',
    //     accessor: 'action',
    //     content: (item: any) => (
    //         <>
    //             <IconButton onClick={() => navigate(`/batch-students/${item.id}`)}>
    //                 <Tooltip title={langString('view')}>
    //                     <VisibilityIcon />
    //                 </Tooltip>
    //             </IconButton>
    //             <ConfirmButton
    //                 color="error"
    //                 icon={<DeleteForeverIcon />}
    //                 subTitle={`Delete Batch ${batchName(item.id)}`}
    //                 confirmed={() => deleteBatch(item.id)}
    //             />
    //             <EditBatch
    //                 batchID={item.id}
    //                 batchName={item.name}
    //                 batchKey={item.enrolmentkey}
    //                 batchCapacity={item.max_students}
    //                 batchSchedules={item.schedules?.length ? item.schedules : ['Sunday']}
    //                 refreshList={() => refreshList()}
    //                 setSnackbar={(value: SnackBarType) => setSnackbar(value)}
    //             />
    //         </>
    //     )
    // }
];

type PublishedQuizPropsType = {
    quizId: string;
};

export default function PublishedQuiz({ quizId }: PublishedQuizPropsType) {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [publishedBatches, setPublishedBatches] = useState([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [loading, setLoading] = useState(false);

    const getPublishedQuizes = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'get_published_quizes'),
                makeFormData([
                    { key: 'course_id', value: course?.id },
                    { key: 'quiz_id', value: quizId }
                ])
            );
            if (response.data.success) {
                if (response.data?.response) {
                    setPublishedBatches(response.data.response);
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };
    // useEffect(() => {
    //     const init = async () => {
    //         setLoading(true);
    //         try {
    //             const response = await axiosServices.post(
    //                 requestUrl('ace_exam', 'get_published_quizes'),
    //                 makeFormData([
    //                     { key: 'course_id', value: course?.id },
    //                     { key: 'quiz_id', value: quizId }
    //                 ])
    //             );
    //             if (response.data.success) {
    //                 if (response.data?.response) {
    //                     setPublishedBatches(response.data.response);
    //                 }
    //                 setLoading(false);
    //             }
    //         } catch (e) {
    //             console.log(e);
    //             setLoading(false);
    //         }
    //     };
    //     if (course) {
    //         init();
    //     } else {
    //         // setSnackbar({ open: true, message: 'Course not selected' });
    //     }
    // }, [course, quizId]);
    return (
        <>
            <Tooltip title={langString('quizpublishingrecord')}>
                <IconButton
                    color="inherit"
                    onClick={() => {
                        setOpen(true);
                        getPublishedQuizes();
                    }}
                >
                    <InfoIcon color="secondary" />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={() => setOpen(false)} TransitionComponent={Transition} keepMounted fullWidth maxWidth="lg">
                <DialogTitle id="alert-dialog-title">{langString('allpublications')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {!loading && <BasicDataTable rows={publishedBatches} columns={PublishQuizColumns} showSL />}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 2.5 }}>
                    <Button
                        sx={{
                            color: theme.palette.error.dark,
                            borderColor: theme.palette.error.dark
                        }}
                        onClick={() => setOpen(false)}
                        color="secondary"
                    >
                        {langString('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
