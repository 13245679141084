import { useRef, useState } from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import langString from 'utils/langString';
import PdfViewer from 'components/Pdf';
import PdfPrintButton from 'components/Pdf/PdfPrintButton';
import AnswerSheet from 'components/Pdf/AnswerSheet';

type QuestionBulkAddPropsType = {
    questions: any[];
    marks: number;
};
export default function QuizPreview({ questions, marks }: QuestionBulkAddPropsType) {
    const [open, setOpen] = useState(false);
    const divRef = useRef<HTMLDivElement | null>(null);
    const answerDivRef = useRef<HTMLDivElement | null>(null);

    return (
        <>
            <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                    setOpen(true);
                }}
            >
                {langString('preview')}
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 1000,
                        bgcolor: 'background.paper',
                        border: '2px solid #EEE',
                        boxShadow: 24,
                        padding: 4,
                        borderRadius: 1
                    }}
                >
                    <Grid container>
                        <Grid item sm={4}>
                            <Typography variant="h3">{langString('preview')}</Typography>
                        </Grid>
                        <Grid item sm={4} sx={{ textAlign: 'center' }}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <PdfPrintButton divRef={divRef} name="Questions" />
                                </Grid>
                                <Grid item>
                                    <PdfPrintButton divRef={answerDivRef} name="Answer Sheet" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={4} sx={{ textAlign: 'right' }}>
                            <Typography variant="subtitle1">
                                {langString('eachmcqmarks')}: {marks}
                            </Typography>
                            <Typography variant="subtitle1">
                                {langString('numberofmcq')}: {questions.length}
                            </Typography>
                            <Typography variant="subtitle1">
                                {langString('totalquizmarks')}: {questions.length * marks}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div
                        style={{
                            maxHeight: 'calc(100vh - 200px)',
                            overflowY: 'auto'
                        }}
                    >
                        <PdfViewer questions={questions} divRef={divRef} />
                        <AnswerSheet questions={questions} divRef={answerDivRef} />
                    </div>
                    <Grid container>
                        <Grid item sm={12}>
                            <Button sx={{ float: 'right' }} variant="outlined" color="error" onClick={() => setOpen(false)}>
                                {langString('close')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
