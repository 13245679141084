import React, { useState } from 'react';
import {
    IconButton,
    Grid,
    Button,
    TextField,
    FormControlLabel,
    RadioGroup,
    Radio,
    Tooltip,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText,
    Checkbox,
    FormGroup
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import DateTimePicker from 'components/common/DateTimePicker';
import InfoIcon from '@mui/icons-material/Info';
import { checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';
import { QuizDeployParamType, QuizNotificationType, QuizType, QuizDurationType } from '.';
import FormValidators from 'utils/FormValidators';

const Transition = React.forwardRef((props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) => (
    <Slide direction="up" ref={ref} {...props} />
));

type PublishQuizModalPropsTypes = {
    open: boolean;
    setOpen: (value: boolean) => void;
    quiz: any;
    setQuiz: (value: any) => void;
    batches: any[];
    setBatches: (value: any) => void;
    batch: number;
    setBatch: (value: number) => void;
    selectedBatches: number[];
    setSelectedBatches: (value: number[]) => void;
    notifications: QuizNotificationType[];
    setNotifications: (value: QuizNotificationType[]) => void;
    qtype: QuizType;
    setQtype: (value: QuizType) => void;
    viewType: number;
    setViewType: (value: number) => void;
    sets: number;
    setSets: (value: number) => void;
    code: string;
    setCode: (value: string) => void;
    quizAttemptType: number;
    setQuizAttemptType: (value: 1 | 2) => void;
    quizTime: string;
    setQuizTime: (value: string) => void;
    quizTimeClose: string;
    setQuizTimeClose: (value: string) => void;
    quizDeadline: string;
    setQuizDeadline: (value: string) => void;
    quizDuration: QuizDurationType;
    setQuizDuration: (value: QuizDurationType) => void;
    quizID: number;
    deploy: (quizID: QuizDeployParamType) => void;
    refreshQuizes: () => void;
};

export default function PublishQuizModal({
    open,
    setOpen,
    quiz,
    setQuiz,
    batches,
    setBatches,
    batch,
    setBatch,
    selectedBatches,
    setSelectedBatches,
    notifications,
    setNotifications,
    qtype,
    setQtype,
    viewType,
    setViewType,
    sets,
    setSets,
    code,
    setCode,
    quizAttemptType,
    setQuizAttemptType,
    quizTime,
    setQuizTime,
    quizTimeClose,
    setQuizTimeClose,
    quizDeadline,
    setQuizDeadline,
    quizDuration,
    setQuizDuration,
    quizID,
    deploy,
    refreshQuizes
}: PublishQuizModalPropsTypes) {
    const [errors, setErrors] = React.useState<any>(null);
    const [showQuizDuration, setShowQuizDuration] = useState(false);

    const changeNotifications = (notify: QuizNotificationType, value: boolean) => {
        const notifiables: QuizNotificationType[] = [...notifications];
        const index = notifiables.indexOf(notify);
        if (value) {
            notifiables.push(notify);
        } else if (index !== -1) {
            notifiables.splice(index, 1);
        }
        setNotifications(notifiables);
    };

    const quizDurationMinutes = () => {
        let minutes: number = 0;
        if (showQuizDuration) {
            minutes = quizDuration.minute;
            if (quizDuration.hour) {
                minutes += quizDuration.hour * 60;
            }
        }
        return minutes;
    };

    const publishQuiz = () => {
        const allErrors = FormValidators(['batch', 'sets'], [batch, sets], ['number', 'positivenumber']);
        if (allErrors.valid && quiz && quiz.details.id) {
            deploy({
                id: quiz.details.id,
                batch,
                quizType: qtype,
                viewType,
                questionSet: sets,
                notifications,
                code,
                quizAttemptType,
                timeOpen: quizTime,
                timeClose: quizTimeClose,
                deadline: qtype === 'deadline' ? quizDeadline : '',
                timeLimit: qtype === 'self' ? 0 : quizDurationMinutes()
            });
            refreshQuizes();
            setOpen(false);
        } else {
            setErrors(allErrors);
        }
    };

    const changeQuizType = (value: 'scheduled' | 'self' | 'deadline' | string) => {
        setQtype(value);
    };
    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 20,
                            top: 20
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={4} sx={{ padding: 4 }}>
                        <Grid item xs={12}>
                            <Typography id="modal-modal-title" variant="h2" component="h2">
                                {langString('quizdeployment')}
                            </Typography>
                        </Grid>
                        <Grid item id="quiz-details-1">
                            <Typography id="modal-modal-title" className="pubish-quiz-header" variant="h2" component="h2">
                                {langString('quizdetails')}
                            </Typography>
                            <Typography id="modal-modal-title" variant="h4" component="h4" sx={{ color: '#dd9f73', paddingTop: 2 }}>
                                {langString('quizname')}
                            </Typography>
                            <Typography variant="h2" component="h2" sx={{ fontWeight: 100 }}>
                                {checkNullInfo(quiz?.details?.name)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} id="quiz-details-2">
                            <Grid container spacing={4}>
                                <Grid item xs={3}>
                                    <Typography variant="h4" component="h4" sx={{ color: '#dd9f73' }}>
                                        {langString('category')}
                                    </Typography>
                                    <Typography variant="h6" component="h6">
                                        {checkNullInfo(quiz?.details?.category_name)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4" component="h4" sx={{ color: '#dd9f73' }}>
                                        {langString('course')}
                                    </Typography>
                                    <Typography variant="h6" component="h6">
                                        {checkNullInfo(quiz?.details?.course_name)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4" component="h4" sx={{ color: '#dd9f73' }}>
                                        {langString('quizdifficulty')}
                                    </Typography>
                                    <Typography variant="h6" component="h6">
                                        {checkNullInfo(quiz?.difficulty?.name.toUpperCase())}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4" component="h4" sx={{ color: '#dd9f73' }}>
                                        {langString('quizcreationdate')}
                                    </Typography>
                                    <Typography variant="h6" component="h6">
                                        {quiz?.details?.timecreated
                                            ? new Date(quiz?.details?.timecreated * 1000).toLocaleDateString()
                                            : '---'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography id="modal-modal-title" className="pubish-quiz-header" variant="h2" component="h2">
                                {langString('batchselectedquestionset')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} id="batch-selected-question-set">
                            <Grid container justifyContent="space-between" spacing={20}>
                                <Grid item xs={6}>
                                    {/* <Autocomplete
                                        size="small"
                                        multiple
                                        options={batches}
                                        getOptionLabel={(option: any) => option.name}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                value="ww"
                                                label={`${langString('batch')} *`}
                                                placeholder={langString('batch')}
                                            />
                                        )}
                                        onChange={(event: any, value: any) => batchChange(event, value)}
                                        // sx={{ width: '80%' }}
                                    />
                                    {errors && errors.batch && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.batch.message}
                                        </FormHelperText>
                                    )} */}
                                    <FormControl fullWidth sx={{ paddingRight: 1 }}>
                                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                                        <Select
                                            size="small"
                                            name="batchs"
                                            labelId="batch-select-label"
                                            id="batch-select"
                                            value={batch}
                                            label={langString('batch')}
                                            onChange={(event: any) => setBatch(event.target.value)}
                                        >
                                            {batches.map((item: any) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {errors && errors.batch && errors.batch.message !== 'Good' && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.batch.message}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id="outlined-sets"
                                        value={sets}
                                        autoFocus
                                        name="sets"
                                        onChange={(event: any) => setSets(event.target.value)}
                                        label={langString('numberofsets')}
                                        // sx={{ width: '80%' }}
                                    />
                                    {errors && errors.sets ? (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.sets.message}
                                        </FormHelperText>
                                    ) : (
                                        <FormHelperText
                                            id="standard-weight-helper-text-email-login"
                                            style={{ color: '#6f04c0', padding: '4px 10px' }}
                                        >
                                            * Sets will have the same questions in different unique orders
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container direction="column" marginTop={4}>
                                <Grid item>
                                    <Typography id="modal-modal-title" className="pubish-quiz-header" variant="h2" component="h2">
                                        {langString('quiztype')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        row
                                        aria-labelledby="question-type-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={viewType}
                                        onChange={(event: any) => setViewType(event.target.value)}
                                    >
                                        <Grid container spacing={20} justifyContent="flex-start">
                                            <Grid item>
                                                <FormControlLabel value={1} control={<Radio />} label={langString('alltogether')} />
                                                <Tooltip disableFocusListener title="Will access question all together">
                                                    <IconButton aria-label="info">
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel value={2} control={<Radio />} label={langString('onebyone')} />
                                                <Tooltip disableFocusListener title="Will access each question separately">
                                                    <IconButton aria-label="info">
                                                        <InfoIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className="pubish-quiz-header" variant="h2" component="h2">
                                {langString('quizrestriction')}
                            </Typography>
                            <Grid container direction="column" spacing={2} sx={{ paddingTop: 4 }}>
                                <Grid item>
                                    <RadioGroup
                                        row
                                        aria-labelledby="qtype-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={qtype}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                            changeQuizType((event.target as HTMLInputElement).value)
                                        }
                                    >
                                        <Grid container spacing={20} justifyContent="flex-start">
                                            <Grid item>
                                                <FormControlLabel
                                                    value="scheduled"
                                                    control={<Radio />}
                                                    label={langString('scheduled')}
                                                    sx={{ color: 'black' }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    value="deadline"
                                                    control={<Radio />}
                                                    label={langString('deadline')}
                                                    sx={{ color: 'black' }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    value="self"
                                                    control={<Radio />}
                                                    label={langString('selfpaced')}
                                                    sx={{ color: 'black' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                                {/* <Grid item>
                                    <Typography variant="h4" component="h2">
                                        {langString('schedule')}
                                    </Typography>
                                </Grid> */}
                                {qtype && qtype === 'scheduled' && (
                                    <>
                                        <Grid item xs={12}>
                                            <Grid container spacing={4} alignItems="center">
                                                <Grid item md={3}>
                                                    <Typography variant="overline" component="h2">
                                                        {langString('quizavailablestudentfrom')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={9}>
                                                    <DateTimePicker setDateTime={(value) => setQuizTime(value)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container spacing={4} alignItems="center">
                                                <Grid item md={3}>
                                                    <Typography variant="overline" component="h2">
                                                        {langString('laststartingtime')}:
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={9}>
                                                    <DateTimePicker setDateTime={(value) => setQuizTimeClose(value)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}

                                {qtype && qtype !== 'self' && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={4} alignItems="center">
                                            <Grid item md={3}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={showQuizDuration}
                                                                onChange={() => setShowQuizDuration(!showQuizDuration)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                            />
                                                        }
                                                        label={langString('quizduration')}
                                                    />
                                                </FormGroup>
                                            </Grid>
                                            {showQuizDuration && (
                                                <Grid item md={9}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                            <TextField
                                                                size="small"
                                                                id="outlined-hour"
                                                                value={quizDuration.hour}
                                                                name="hour"
                                                                type="number"
                                                                onChange={(event: any) =>
                                                                    setQuizDuration({
                                                                        hour: event.target.value,
                                                                        minute: quizDuration.minute
                                                                    })
                                                                }
                                                                label={langString('hour')}
                                                                placeholder={langString('hour')}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <TextField
                                                                size="small"
                                                                id="outlined-minute"
                                                                value={quizDuration.minute}
                                                                name="minute"
                                                                type="number"
                                                                onChange={(event: any) =>
                                                                    setQuizDuration({ hour: quizDuration.hour, minute: event.target.value })
                                                                }
                                                                label={langString('minute')}
                                                                placeholder={langString('minute')}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )}
                                {qtype && qtype === 'deadline' && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={4} alignItems="center">
                                            <Grid item md={3}>
                                                <Typography variant="overline" component="h2">
                                                    {langString('lastsubmissiontime')}:
                                                </Typography>
                                            </Grid>
                                            <Grid item md={9}>
                                                <DateTimePicker setDateTime={(value) => setQuizDeadline(value)} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className="pubish-quiz-header" variant="h2" component="h2">
                                {langString('accesscode')}
                            </Typography>
                            <Grid container spacing={4} justifyContent="flex-start" alignItems="center" sx={{ paddingTop: 2 }}>
                                <Grid item>
                                    <Typography variant="h4" component="h2">
                                        {langString('lockquizwithaccesscode')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        size="small"
                                        id="outlined-code"
                                        value={code}
                                        name="code"
                                        onChange={(event: any) => setCode(event.target.value)}
                                        label={langString('accesscode')}
                                        placeholder={langString('accesscode')}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" marginTop={4}>
                                <Grid item>
                                    <Typography id="modal-modal-title" className="pubish-quiz-header" variant="h2" component="h2">
                                        {langString('classwork')}/{langString('homework')}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <RadioGroup
                                        row
                                        aria-labelledby="question-type-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={quizAttemptType}
                                        onChange={(event: any) => setQuizAttemptType(event.target.value)}
                                    >
                                        <Grid container spacing={20} justifyContent="flex-start">
                                            <Grid item>
                                                <FormControlLabel value={1} control={<Radio />} label={langString('classwork')} />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel value={2} control={<Radio />} label={langString('homework')} />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography className="pubish-quiz-header" variant="h2" component="h2">
                                {langString('notifications')}
                            </Typography>
                            <Grid container direction="column" sx={{ paddingTop: 2 }}>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked />}
                                        label={langString('emailtostudent')}
                                        checked={notifications.includes('emailStudent')}
                                        onChange={() => changeNotifications('emailStudent', !notifications.includes('emailStudent'))}
                                        sx={{ color: 'black' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked />}
                                        label={langString('emailtoparents')}
                                        checked={notifications.includes('emailParents')}
                                        onChange={() => changeNotifications('emailParents', !notifications.includes('emailParents'))}
                                        sx={{ color: 'black' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked />}
                                        label={langString('smstostudent')}
                                        checked={notifications.includes('smsStudent')}
                                        onChange={() => changeNotifications('smsStudent', !notifications.includes('smsStudent'))}
                                        sx={{ color: 'black' }}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={<Checkbox defaultChecked />}
                                        label={langString('smstoparents')}
                                        checked={notifications.includes('smsParents')}
                                        onChange={() => changeNotifications('smsParents', !notifications.includes('smsParents'))}
                                        sx={{ color: 'black' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={6} sx={{ margin: '0 auto', textAlign: 'center', paddingTop: 3 }}>
                            <Button variant="contained" onClick={() => publishQuiz()}>
                                {langString('publishquiz')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}
