import { useState, useEffect } from 'react';
import { Box, FormControl, Grid, Typography, InputLabel, Tooltip, Select, MenuItem, IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ReactApexChart from 'react-apexcharts';
import axiosServices from 'utils/axiosServices';
// import { marksColor } from 'utils/Helpers';
import { makeFormData, requestUrl } from 'utils/Helpers';
import langString from 'utils/langString';
import { PieOptions, pieChartColor } from 'utils/ChartTypes';
import BasicDataTable, { ColumnType } from 'components/Table/BasicDataTable';
import { useNavigate } from 'react-router-dom';

type DashboardChartGroupPropsType = {
    batches: any[];
};
export default function DashboardChartGroup({ batches }: DashboardChartGroupPropsType) {
    const [options, setOptions] = useState<PieOptions | null>(null);
    const [series, setSeries] = useState<number[]>([]);
    const [batchID, setBatchID] = useState(0);
    const [group, setGroup] = useState('');
    const [students, setStudents] = useState<any[]>([]);
    const navigate = useNavigate();

    const StudentColumns: ColumnType[] = [
        {
            header: langString('name'),
            accessor: 'name'
        },
        {
            header: langString('attemptedQuizPercentage'),
            accessor: 'attemptedQuizPercentage'
        },
        {
            header: 'Profile',
            accessor: 'profile',
            content: (item: any) => (
                <IconButton color="primary" onClick={() => navigate(`/student-profile/${item.userid}`)}>
                    <Tooltip title={langString('view')}>
                        <PersonIcon />
                    </Tooltip>
                </IconButton>
            )
        }
    ];

    useEffect(() => {
        if (batches && batches.length) {
            setBatchID(batches[0].id);
        }
    }, [batches]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('dashboard', 'student_result_status_report'),
                    makeFormData([{ key: 'batch_id', value: batchID }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        const labels = Object.keys(response.data.response);
                        const dataList: number[] = [];
                        labels.forEach((item) => dataList.push(response.data.response[item].length));
                        setSeries(dataList);
                        setOptions({
                            chart: {
                                width: 380,
                                type: 'pie',
                                toolbar: {
                                    show: true,
                                    offsetX: 0,
                                    offsetY: 0,
                                    tools: {
                                        download: true,
                                        selection: true,
                                        zoom: true,
                                        zoomin: true,
                                        zoomout: true,
                                        pan: true,
                                        reset: true,
                                        customIcons: []
                                    },
                                    export: {
                                        csv: {
                                            filename: 'AverageMarkComp',
                                            columnDelimiter: ',',
                                            headerCategory: 'Student Name',
                                            headerValue: 'value',
                                            dateFormatter(timestamp: any) {
                                                return new Date(timestamp).toDateString();
                                            }
                                        },
                                        svg: {
                                            filename: 'AverageMarkComp'
                                        },
                                        png: {
                                            filename: 'AverageMarkComp'
                                        }
                                    },
                                    autoSelected: 'zoom'
                                },
                                events: {
                                    dataPointSelection(event: any, chartContext: any, config: any) {
                                        setGroup(labels[config.dataPointIndex]);
                                        setStudents(response.data.response[labels[config.dataPointIndex]]);
                                    }
                                }
                            },
                            plotOptions: {
                                pie: {
                                    distributed: true,
                                    expandOnClick: true
                                }
                            },
                            labels,
                            // colors: dataList.map((mark: number) => marksColor(mark)),
                            colors: pieChartColor,
                            responsive: [
                                {
                                    breakpoint: 480,
                                    options: {
                                        chart: {
                                            width: 200
                                        },
                                        legend: {
                                            position: 'bottom'
                                        }
                                    }
                                }
                            ],

                            markers: {
                                size: 10
                            }
                        });
                    } else {
                        setOptions(null);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (batchID) {
            init();
        }
    }, [batchID]);

    return (
        <Box sx={{ marginY: 3, borderBottom: '1px solid #DDD', padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item sm={4}>
                    <Box sx={{ padding: 1.5, border: '1px solid #e67e22', borderRadius: '10px', textAlign: 'center' }}>
                        <Typography variant="h5" sx={{ color: '#e67e22' }}>
                            Student Result Status Report for All Quizzes
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={8}>
                    <FormControl sx={{ paddingRight: 1, float: 'right', minWidth: '150px' }}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            size="small"
                            name="batchs"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batchID}
                            label={langString('batch')}
                            onChange={(event: any) => setBatchID(event.target.value)}
                        >
                            {batches.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{ marginY: 1 }}>
                <Grid item>
                    <Typography variant="h5">Click a group to see respective student&apos;s list</Typography>
                </Grid>
                <Grid item sm={12}>
                    {options && series ? (
                        <ReactApexChart options={options} series={series} type="pie" width={380} />
                    ) : (
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            {langString('nodatafound')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            {students && group && (
                <Grid container spacing={2} sx={{ marginY: 1 }}>
                    <Grid item sm={12}>
                        <Typography variant="h4" sx={{ textAlign: 'center', padding: 2 }}>
                            List of students in <strong>{group}</strong> Group
                        </Typography>
                        <BasicDataTable rows={students} columns={StudentColumns} />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
}
