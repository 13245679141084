import { useEffect, useState } from 'react';
import { Autocomplete, Button, FormControl, FormHelperText, Grid, TextareaAutosize, TextField, Typography } from '@mui/material';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { makeFormData, requestUrl } from 'utils/Helpers';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import FormValidators from 'utils/FormValidators';

export default function GeneralNotice() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [batches, setBatches] = useState<any[]>([]);
    const [selectedBatches, setSelectedBatches] = useState<number[]>([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    const batchChange = (event: any, value: any) => {
        if (value) {
            const batchIDs = value.map((item: any) => item.id);
            setSelectedBatches(batchIDs);
        }
    };

    const sendNotice = async () => {
        if (course) {
            setIsSubmitting(true);
            const allErrors = FormValidators(['title', 'description'], [title, description]);
            if (allErrors.valid) {
                setErrors(null);
                try {
                    const response = await axiosServices.post(
                        requestUrl('ace_exam', 'general_notice'),
                        makeFormData([
                            { key: 'course_id', value: course?.id },
                            { key: 'batch', value: selectedBatches },
                            { key: 'title', value: title },
                            { key: 'description', value: description }
                        ])
                    );
                    if (response.data.success) {
                        setSelectedBatches([]);
                        setTitle('');
                        setDescription('');
                        setSnackbar({ open: true, message: langString('noticepublished'), alert: 'success' });
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                setErrors(allErrors);
            }
            setIsSubmitting(false);
        } else {
            setSnackbar({ open: true, message: langString('coursenotselected') });
        }
    };

    useEffect(() => {
        setLoading(true);
        if (course && course.batches) {
            setBatches(course.batches);
            if (!batches) {
                setSnackbar({ open: true, message: langString('nobatchavailable') });
            } else {
                setLoading(false);
            }
        }
    }, [course, batches]);

    return (
        <Wrapper
            title={langString('generalnotice')}
            icon={<CampaignIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <Grid container>
                <Grid item sm={12}>
                    <Typography variant="subtitle1" sx={{ marginBottom: 3 }}>
                        {langString('createnotice')}
                    </Typography>
                    <FormControl fullWidth error={Boolean(errors && errors.batches?.required)}>
                        <Autocomplete
                            size="small"
                            multiple
                            options={batches}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} label={`${langString('batch')}`} placeholder={langString('batch')} />
                            )}
                            onChange={(event: any, value: any) => batchChange(event, value)}
                        />
                        {errors && errors.batches?.required && (
                            <FormHelperText error id="helper-text-batches">
                                {errors.batches?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl sx={{ marginTop: 3 }} fullWidth error={Boolean(errors && errors.title?.required)}>
                        <TextField
                            id="outlined-title"
                            value={title}
                            autoFocus
                            name="title"
                            onChange={(event: any) => setTitle(event.target.value)}
                            label={langString('title')}
                            placeholder={langString('title')}
                            inputProps={{}}
                        />
                        {errors && errors.title?.required && (
                            <FormHelperText error id="helper-text-title">
                                {errors.title?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <FormControl sx={{ marginBottom: 3, marginTop: 3 }} fullWidth error={Boolean(errors && errors.description?.required)}>
                        <TextareaAutosize
                            value={description}
                            onChange={(event: any) => setDescription(event.target.value)}
                            minRows={9}
                            placeholder={langString('description')}
                            style={{ minWidth: 500 }}
                        />
                        {errors && errors.description?.required && (
                            <FormHelperText error id="helper-text-description">
                                {errors.description?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Grid container>
                        <Grid item sm={12}>
                            <Button
                                sx={{ float: 'right' }}
                                variant="contained"
                                color="primary"
                                onClick={() => sendNotice()}
                                disabled={isSubmitting}
                            >
                                {langString('sendnotice')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    );
}
