/*eslint-disable*/
import { useState, useEffect } from 'react';
import _ from 'lodash';

import { Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import langString from 'utils/langString';

const hours = _.range(1, 13, 1);
const minutes = _.range(0, 60, 1);

type DateTimePickerPropsType = {
    setDateTime: (ymd: string) => void;
};
export default function DateTimePicker({ setDateTime }: DateTimePickerPropsType) {
    // const [day, setDay] = useState(1);
    const today = new Date();

    const [day, setDay] = useState(Number(String(today.getDate()).padStart(2, '0')));
    const [month, setMonth] = useState(Number(String(today.getMonth() + 1).padStart(2, '0')));
    const [year, setYear] = useState(Number(today.getFullYear()));
    const getHourInFormat = () => {
        let h: number;
        if (today.getHours() === 0) {
            h = 12;
        } else if (today.getHours() > 0 && today.getHours() <= 12) {
            h = today.getHours();
        } else {
            h = today.getHours() - 12;
        }
        return h;
    };
    const [hour, setHour] = useState(getHourInFormat());
    const [minute, setMinute] = useState(today.getMinutes());
    const [days, setDays] = useState<number[]>(_.range(1, 32, 1));
    const [period, setPeriod] = useState(today.getHours() < 12 ? 'AM' : 'PM');

    useEffect(() => {
        setDateTime(`${year}-${month}-${day} ${getRevHour()}`);
    }, [period, year, month, day, hour, minute]);
    const changeMonth = (value: number) => {
        setMonth(value);
        if (value === 2) {
            setDays(_.range(1, 30, 1));
        } else if ([9, 4, 6, 11].includes(value)) {
            setDays(_.range(1, 31, 1));
        } else {
            setDays(_.range(1, 32, 1));
        }
    };
    const months = _.range(1, 13, 1);
    const years = _.range(1950, 2040, 1);

    const getMonthName = (mon: number) => {
        const d = new Date(2018, 4, 3, 21, 0);

        d.setMonth(mon - 1);

        const monName = d.toLocaleString('default', { month: 'long' });

        return monName;
    };
    const getRevHour = () => {
        let min = JSON.parse(JSON.stringify(minute));
        let ho = JSON.parse(JSON.stringify(hour));
        console.log('min0', min);
        if (period === 'PM' && ho < 12) {
            ho += 12;
        }
        if (period === 'AM' && ho === 12) {
            ho -= 12;
        }
        let temp_hour = ho.toString();
        let temp_minute = min.toString();

        if (ho < 10) temp_hour = '0' + ho.toString();
        if (min < 10) temp_minute = '0' + min.toString();
        console.log('temp_hour', temp_hour, temp_minute);
        return temp_hour + ':' + temp_minute;
    };
    // const setTime = () => {
    // console.log(year, month, day, hour, minute);
    // setDateTime(`${year}-${month}-${day} ${hour}:${minute}`);
    // };
    const changeDateTime = (element: string, value: any) => {
        console.log('datetime', element, value);
        if (element === 'year') {
            setYear(value);
        }
        if (element === 'month') {
            setMonth(value);
            changeMonth(value);
        }
        if (element === 'day') {
            setDay(value);

            // console.log('day', day.current);
        }
        if (element === 'hour') {
            setHour(value);
        }
        if (element === 'minute') {
            setMinute(value);
        }
        if (element === 'period') {
            setPeriod(value);
        }

        // setDateTime(`${year.current}-${month.current}-${day.current} ${getRevHour(period, hour.current)}:${minute.current}`);
    };

    return (
        <Grid container spacing={1}>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="year-select-label">{langString('year')}</InputLabel>
                    <Select
                        size="small"
                        labelId="year-select-label"
                        id="year-select"
                        value={year}
                        label="Year"
                        onChange={(event: any) => changeDateTime('year', event.target.value)}
                    >
                        {years.map((item: number) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="month-select-label">{langString('month')}</InputLabel>
                    <Select
                        size="small"
                        labelId="month-select-label"
                        id="month-select"
                        value={month}
                        label="Month"
                        onChange={(event: any) => changeDateTime('month', event.target.value)}
                    >
                        {months.map((item: number) => (
                            <MenuItem key={item} value={item}>
                                {getMonthName(item)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="day-select-label">{langString('day')}</InputLabel>
                    <Select
                        size="small"
                        labelId="day-select-label"
                        id="day-select"
                        value={day}
                        label="Day"
                        onChange={(event: any) => changeDateTime('day', event.target.value)}
                    >
                        {days.length &&
                            days.map((item: number) => (
                                <MenuItem key={item} value={item}>
                                    {item < 10 ? `0${item}` : item}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="hour-select-label">{langString('hour')}</InputLabel>
                    <Select
                        size="small"
                        labelId="hour-select-label"
                        id="hour-select"
                        value={hour}
                        label="Hour"
                        onChange={(event: any) => changeDateTime('hour', event.target.value)}
                    >
                        {hours.length &&
                            hours.map((item: number) => (
                                <MenuItem key={item} value={item}>
                                    {item < 10 ? `0${item}` : item}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="minute-select-label">{langString('minute')}</InputLabel>
                    <Select
                        size="small"
                        labelId="minute-select-label"
                        id="minute-select"
                        value={minute}
                        label="Minute"
                        onChange={(event: any) => changeDateTime('minute', event.target.value)}
                    >
                        {minutes.length &&
                            minutes.map((item: number) => (
                                <MenuItem key={item} value={item}>
                                    {item < 10 ? `0${item}` : item}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl fullWidth>
                    <InputLabel id="period-select-label">{langString('period')}</InputLabel>
                    <Select
                        size="small"
                        labelId="period-select-label"
                        id="period-select"
                        value={period}
                        label="period"
                        onChange={(event: any) => {
                            console.log('period', event.target.value);
                            // period.current = event.target.value;
                            changeDateTime('period', event.target.value);
                        }}
                    >
                        <MenuItem key="AM" value="AM">
                            AM
                        </MenuItem>
                        <MenuItem key="PM" value="PM">
                            PM
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
