// project imports
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { GuardProps } from 'types';
import Home from 'views/Home';
import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';
import langString from 'utils/langString';
// import CourseGuardLayout from 'layout/MainLayout/CourseGuardLayout';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const CourseGuard = ({ children }: GuardProps) => {
    // const [courseId] = useState(localStorage.getItem('courseID'));
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        if (!localStorage.getItem('courseID') && location.pathname !== '/') {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: langString('selectcoursetocontinue'),
                variant: 'alert',
                alertSeverity: 'error'
            });
            navigate('/');
        }
    }, [dispatch, location.pathname, navigate]);

    return !localStorage.getItem('courseID') ? <Home /> : children;
};

export default CourseGuard;
