import { TableCell, TableHead, TableRow, Typography } from '@mui/material';
import langString from 'utils/langString';

export default function QuizTableHeader() {
    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        SL
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        {langString('quizname')}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        {langString('accesscode')}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        {langString('datecreated')}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        {langString('course')}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        {langString('chaptertopic')}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        {langString('difficulty')}
                    </Typography>
                </TableCell>
                {/* <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        {langString('status')}
                    </Typography>
                </TableCell> */}
                <TableCell align="center">
                    <Typography variant="subtitle1" noWrap>
                        {langString('action')}
                    </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}
