/* eslint-disable react/no-danger */
import { Box } from '@mui/material';
import { MathJax } from 'better-react-mathjax';
import { answerOption } from 'utils/Helpers';

type HtmlRenderTypes = {
    content?: string;
    ansKey: number;
};
const ContentViewerAns = ({ content, ansKey }: HtmlRenderTypes) => (
    <Box sx={{ display: 'block' }}>
        {content ? (
            <MathJax hideUntilTypeset="first">
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </MathJax>
        ) : (
            <MathJax hideUntilTypeset="first">{` (${answerOption(ansKey)})`}</MathJax>
        )}
    </Box>
);
export default ContentViewerAns;
