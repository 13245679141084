import { Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import FilterListIcon from '@mui/icons-material/FilterList';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { checkNullInfo, checkDecimal } from 'utils/Helpers';
import _ from 'lodash';
// Props type
export type ColumnType = {
    header: string;
    accessor: string;
    content?: any;
};

type TableProps = {
    columns: ColumnType[];
    rows: any[];
    count?: number;
    page?: number;
    rowsPerPage?: number;
    link?: string;
    linkID?: string;
    idField?: string;
    statusField?: string;
    showSL?: boolean;
    sortables?: string[];
    setPage?: (value: number) => void;
    setRowsPerPage?: (value: number) => void;
    updateStatus?: (id: string) => void;
    updateRows?: (data: any[]) => void;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function BasicDataTable({
    columns,
    rows,
    count,
    page,
    rowsPerPage,
    link,
    linkID,
    showSL,
    sortables,
    idField,
    statusField,
    setPage,
    setRowsPerPage,
    updateStatus,
    updateRows
}: TableProps) {
    const navigate = useNavigate();
    const classes = useStyle();

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        if (setPage) {
            setPage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (setRowsPerPage && setPage) {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(1);
        }
    };

    const renderCell = (item: any, column: any) => {
        if (column.content) {
            return column.content(item);
        }
        return <Typography noWrap>{checkNullInfo(checkDecimal(item[column.accessor]))}</Typography>;
    };

    const sortByColumn = (columnKey: string) => {
        const allRows = [...rows];
        if (updateRows) {
            const sorted = _.sortBy(allRows, [
                // eslint-disable-next-line func-names
                function (o) {
                    return o[columnKey];
                }
            ]);
            updateRows(sorted);
        }
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard content={false}>
                    <TableContainer>
                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {showSL && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                Sl
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {columns.map((column: any, index: number) =>
                                        sortables && sortables.includes(column.accessor) ? (
                                            <TableCell key={index} align="center">
                                                <Typography
                                                    variant="subtitle1"
                                                    noWrap
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => sortByColumn(column.accessor)}
                                                >
                                                    {column.header} <FilterListIcon />
                                                </Typography>
                                            </TableCell>
                                        ) : (
                                            <TableCell key={index} align="center">
                                                <Typography variant="subtitle1" noWrap>
                                                    {column.header}
                                                </Typography>
                                            </TableCell>
                                        )
                                    )}
                                    {statusField && idField && updateStatus && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                Update Status
                                            </Typography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length ? (
                                    rows.map((row: any, i: number) => (
                                        <TableRow
                                            key={i}
                                            className={classes.rowsHover}
                                            onClick={() => (linkID ? navigate(`${link + row[linkID]}/`) : '')}
                                            sx={{ cursor: linkID ? 'pointer' : 'default' }}
                                        >
                                            {showSL && (
                                                <TableCell align="center">
                                                    <Typography variant="subtitle1" noWrap>
                                                        {rowsPerPage && page ? page * rowsPerPage + i + 1 : i + 1}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {columns.map((column: ColumnType, k: number) => (
                                                <TableCell align="center" key={k}>
                                                    {renderCell(row, column)}
                                                </TableCell>
                                            ))}
                                            {statusField && idField && updateStatus && (
                                                <TableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        color={row[statusField] ? 'success' : 'primary'}
                                                        size="small"
                                                        onClick={() => updateStatus(row[idField])}
                                                    >
                                                        {row[statusField] ? 'Cancel Approval' : 'Approve'}
                                                    </Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow className={classes.rowsHover}>
                                        <TableCell align="center" colSpan={showSL ? columns.length + 1 : columns.length}>
                                            <h3>No Data Found</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {count
                        ? page !== undefined &&
                          rowsPerPage !== undefined && (
                              <TablePagination
                                  component="div"
                                  count={count}
                                  page={page}
                                  onPageChange={handleChangePage}
                                  rowsPerPage={rowsPerPage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                          )
                        : ''}
                </MainCard>
            </Grid>
        </Grid>
    );
}

const useStyle = makeStyles({
    rowsHover: {
        borderBottom: '1px solid #DDD',
        '&:hover': {
            backgroundColor: '#FFE'
        }
    }
});
