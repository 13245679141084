/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import {
    Box,
    Card,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Button
} from '@mui/material';
import { WeekDays, scheduleDay, schedulePeriod } from 'utils/date';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl, chipify } from 'utils/Helpers';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import { useNavigate } from 'react-router-dom';

type ScheduleType = {
    day: string;
    period: string;
    batchID: number;
    batchName: string;
};

type ScheduleObjects = {
    hours: string[];
    schedules: ScheduleType[];
};

const makeScheduleObjects = (batchesArray: any[]): ScheduleObjects => {
    const schedules: ScheduleType[] = [];
    const hours: string[] = [];
    batchesArray.forEach((item: any) => {
        item.schedules?.forEach((element: string) => {
            if (element) {
                const day = scheduleDay(element);
                const period = schedulePeriod(element);
                if (period) {
                    hours.push(period);
                    schedules.push({ day, period, batchID: item.id, batchName: item.name });
                }
            }
        });
    });
    const uniqueHours = Array.from(new Set(hours)).sort();
    return { schedules, hours: uniqueHours };
};

const makeScheduleMatrix = (batchesArray: any[]) => {
    const scObjects: ScheduleObjects = makeScheduleObjects(batchesArray);
    const matrix: string[][] = [];
    const row: string[] = ['#'];
    const timeFormat = (hour: string) => {
        let h: string;
        if (Number(hour.slice(0, 2)) === 0) {
            h = `12:${hour.slice(3, 5)} AM`;
        } else if (Number(hour.slice(0, 2)) === 12) {
            h = `12:${hour.slice(3, 5)} PM`;
        } else if (Number(hour.slice(0, 2)) > 0 && Number(hour.slice(0, 2)) < 12) {
            h = `${Number(hour.slice(0, 2))}:${hour.slice(3, 5)} AM`;
        } else {
            h = `${Number(hour.slice(0, 2)) - 12}:${hour.slice(3, 5)} PM`;
        }
        return h;
    };

    const getHour = (hour: string) => `${timeFormat(hour.slice(0, 5))} - ${timeFormat(hour.slice(6, 11))}`;
    scObjects.hours.forEach((hour) => row.push(getHour(hour)));
    matrix.push(row);
    WeekDays.forEach((day) => {
        const scRow: string[] = [day];
        scObjects.hours.forEach((hour) => {
            let nowCells: string = '';
            scObjects.schedules.forEach((mo) => {
                if (mo.day === day && mo.period === hour) {
                    nowCells += chipify(mo.batchName);
                }
            });
            scRow.push(nowCells);
        });
        matrix.push(scRow);
    });
    return matrix;
};

export default function DashboardRoutine() {
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [totalStudent, setTotalStudent] = useState(0);
    const [batches, setBatches] = useState<any[]>([]);
    const [schedules, setSchedules] = useState<string[][]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('dashboard', 'teacher_dashboard_course_details'),
                    makeFormData([{ key: 'course_id', value: course?.id }])
                );
                if (response.data.success) {
                    if (response.data?.response) {
                        setTotalStudent(response.data.response?.totalStudent);
                        setBatches(response.data.response?.batches);
                        setSchedules(makeScheduleMatrix(response.data.response?.batches));
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (course.id) {
            init();
        }
    }, [course.id]);

    return (
        <Box sx={{ marginY: 3, borderTop: '1px solid #DDD', borderBottom: '1px solid #DDD', padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item sm={3}>
                    <Typography variant="h3">{course?.name}</Typography>
                    <Button sx={{ width: '100%' }}>
                        <Card
                            variant="outlined"
                            sx={{
                                background: '#fef18a',
                                marginY: 2,
                                paddingY: 4,
                                textAlign: 'center',
                                '&:hover': {
                                    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)'
                                },
                                width: '100%'
                            }}
                            onClick={() => navigate('/enrolment', { replace: true })}
                        >
                            <Typography variant="h6">Total Students</Typography>
                            <Typography variant="h2">{totalStudent}</Typography>
                        </Card>
                    </Button>
                    <Button sx={{ width: '100%' }}>
                        <Card
                            variant="outlined"
                            sx={{
                                background: '#aaffd9',
                                marginY: 2,
                                paddingY: 4,
                                textAlign: 'center',
                                '&:hover': {
                                    boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.3)'
                                },
                                width: '100%'
                            }}
                            onClick={() => navigate('/batch', { replace: true })}
                        >
                            <Typography variant="h6">Total Batches</Typography>
                            <Typography variant="h2">{batches.length}</Typography>
                        </Card>
                    </Button>
                </Grid>
                <Grid item sm={9}>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {schedules.length &&
                                        schedules[0].map((row) => <TableCell sx={{ border: '1px solid #EEE' }}>{row}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {schedules.length &&
                                    schedules.map(
                                        (routine, index: number) =>
                                            index > 0 && (
                                                <TableRow sx={{ background: new Date().getDay() === index ? '#dff9fb' : '#FFF' }}>
                                                    {routine.map((row, ik: number) => (
                                                        <TableCell
                                                            sx={{
                                                                border: '1px solid #EEE',
                                                                fontWeight: ik === 0 ? '600' : '400'
                                                            }}
                                                        >
                                                            <div dangerouslySetInnerHTML={{ __html: row }} />
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            )
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
}
