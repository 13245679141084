import { useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';

import { getAttendenceStatusColor, makeFormData, requestUrl } from 'utils/Helpers';
import SelectTable, { SelectColumnType } from 'components/Table/SelectTable';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
// import Chip from 'ui-component/extended/Chip';
import Chip from '@mui/material/Chip';
import { SnackBarType } from 'types/common';

type OverViewReportPropsType = {
    loading: boolean;
    setLoading: (value: boolean) => void;
    batches: any;
    setBatches?: (value: any) => void;
    batchID: number;
    setBatchID: (value: number) => void;
    snackbar: SnackBarType;
    setSnackbar: (value: SnackBarType) => void;
};

export default function OverviewReport({
    loading,
    setLoading,
    batches,
    setBatches,
    batchID,
    setBatchID,
    snackbar,
    setSnackbar
}: OverViewReportPropsType) {
    const [students, setStudents] = useState<any[]>([]);

    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [checkAll, setCheckAll] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    const StudentColumns: SelectColumnType[] = [
        {
            header: langString('name'),
            accessor: 'name'
        },
        {
            header: langString('email'),
            accessor: 'email'
        },
        {
            header: langString('phone'),
            accessor: 'phone'
        },
        {
            header: langString('totalclass'),
            accessor: 'totalClass'
        },
        {
            header: langString('totalpresent'),
            accessor: 'totalPresent'
        },
        // {
        //     header: langString('totalabsent'),
        //     accessor: 'totalAbsent'
        // },
        {
            header: 'Attendence Status',
            accessor: 'action',
            content: (item: any) => (
                <Typography variant="h4" gutterBottom>
                    <Chip label={`Misses ${item.totalAbsent} classes`} color={getAttendenceStatusColor(item)} />
                </Typography>
            )
        }
    ];

    const selectAllStudents = (value: boolean) => {
        if (value) {
            setCheckAll(true);
            if (students) {
                const allStudents: number[] = [];
                students.forEach((st) => allStudents.push(st.userid));
                setSelectedStudents(allStudents);
            }
        } else {
            setCheckAll(false);
            setSelectedStudents([]);
        }
    };

    const selectSingleStudent = (studentID: number) => {
        const allSelectedStudents = [...selectedStudents];
        if (allSelectedStudents.includes(studentID)) {
            const reduced = allSelectedStudents.filter((item) => item !== studentID);
            setSelectedStudents(reduced);
        } else {
            allSelectedStudents.push(studentID);
            setSelectedStudents(allSelectedStudents);
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'attendence_overview_report'),
                    makeFormData([
                        { key: 'course_id', value: course?.id },
                        { key: 'batch_id', value: batchID },
                        { key: 'search', value: search },
                        { key: 'rows_per_page', value: rowsPerPage },
                        { key: 'page_no', value: page }
                    ])
                );
                if (response.data.success) {
                    if (response.data?.response?.data) {
                        setStudents(response.data.response?.data);
                        setCount(response.data.response?.totalCount);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (course && batchID) {
            init();
        }
    }, [course, batchID, search, rowsPerPage, page]);

    return (
        <>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth sx={{ paddingRight: 1 }}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            size="small"
                            name="batchs"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batchID}
                            label={langString('batch')}
                            onChange={(event: any) => setBatchID(event.target.value)}
                        >
                            {batches.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        id="outlined-search"
                        value={search}
                        name="search"
                        size="small"
                        onChange={(event: any) => setSearch(event.target.value)}
                        label={langString('search')}
                        placeholder={langString('search')}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={12}>
                    <SelectTable
                        idField="userid"
                        rows={students}
                        columns={StudentColumns}
                        showSL
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={(value: number) => setPage(value)}
                        setRowsPerPage={(value: number) => setRowsPerPage(value)}
                        selectedFields={selectedStudents}
                        checkAll={checkAll}
                        changeCheckAll={(value: boolean) => selectAllStudents(value)}
                        checkUpdate={(id: number) => selectSingleStudent(id)}
                    />
                </Grid>
            </Grid>
        </>
    );
}
