import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Tooltip
} from '@mui/material';
import langString from 'utils/langString';
import Swal from 'sweetalert2';

type ConfirmButtonPropsType = {
    icon?: React.ReactNode;
    title?: string;
    subTitle?: string;
    response?: string;
    color?: 'error' | 'success' | 'default' | 'primary' | 'secondary';
    confirmed: () => void;
};
export default function ConfirmButton({ icon, title, subTitle, color, response, confirmed }: ConfirmButtonPropsType) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const confirm = () => {
        Swal.fire({
            title,
            text: subTitle,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Submit!'
        }).then((result) => {
            if (result.isConfirmed) {
                confirmed();
                // Swal.fire(response || 'Done!', `${response} successfully`, 'success'); // Display success message
            }
        });
    };

    return (
        <>
            <Tooltip title={title || langString('delete')}>
                <IconButton
                    color={color || 'inherit'}
                    onClick={() => {
                        confirm();
                        setOpen(false);
                    }}
                >
                    {icon || langString('delete')}
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                <DialogTitle id="alert-dialog-title">{title || 'Are you sure?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography variant="body2" component="span">
                            {subTitle && subTitle}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ pr: 2.5 }}>
                    <Button
                        sx={{
                            color: theme.palette.error.dark,
                            borderColor: theme.palette.error.dark
                        }}
                        onClick={() => setOpen(false)}
                        color="secondary"
                    >
                        {langString('cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                            confirmed();
                            setOpen(false);
                        }}
                        autoFocus
                    >
                        {langString('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
