import { useEffect, useState } from 'react';
import { Grid, Typography, Paper, styled, Grow } from '@mui/material';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
// import HomeIcon from '@mui/icons-material/Home';
import { groupByKey, requestUrl } from 'utils/Helpers';
import CourseItem from './CourseItem';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#dbeeff',
    ...theme.typography.h3,
    padding: theme.spacing(2),
    textAlign: 'left',
    elevation: 16,
    color: 'black'
}));

export default function Home() {
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState<any[]>([]);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    useEffect(() => {
        const init = async () => {
            setLoading(true);
            try {
                const response = await axiosServices.get(requestUrl('ace_exam', 'teacher_courses'));
                if (response.data.success) {
                    if (response.data?.response?.length) {
                        const allCategories: any[] = groupByKey(response.data?.response, 'category_name');
                        setCategories(allCategories);
                    }
                    setLoading(false);
                }
            } catch (e) {
                setLoading(false);
                setSnackbar({ open: true, message: langString('servererror') });
            }
        };
        init();
    }, []);

    return (
        <Wrapper
            // title={langString('home')}
            // icon={<HomeIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
            mainCardBorder={false}
        >
            {categories && categories.length ? (
                categories.map((category: any, index: number) => (
                    <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 + (index + 1) * 200 }}>
                        <Grid key={category.key} container sx={{ marginBottom: 2, padding: 1, borderBottom: '1px solid #DDD' }}>
                            {/* <Grid item sm={12}>
                            <Typography variant="h4" sx={{ padding: 1 }}>
                                <span style={{ color: '#7f8c8d' }}>{langString('category')} :</span> {category.key}
                            </Typography>
                        </Grid> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Item>
                                        <span style={{ color: '#7f8c8d' }}>{langString('category')} :</span> {category.key}
                                    </Item>
                                </Grid>
                            </Grid>
                            <Grid container spacing={4} margin={2}>
                                {category.value &&
                                    category.value.map((catCourse: any) => (
                                        <Grid item sm={4} key={catCourse.id}>
                                            <CourseItem
                                                selected={course && course.id === catCourse.id}
                                                id={catCourse.id}
                                                name={catCourse.course_name}
                                                categoryID={catCourse.category_id}
                                                categoryName={catCourse.category_name}
                                                image={catCourse.coursepic}
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                    </Grow>
                ))
            ) : (
                <>
                    <Typography variant="h3" sx={{ margin: 5, textAlign: 'center' }}>
                        {langString('nocourseavailable')}
                    </Typography>
                    <Typography variant="h3" sx={{ textAlign: 'center' }}>
                        {langString('pleasecontactwithadmin')}
                    </Typography>
                </>
            )}
        </Wrapper>
    );
}
