/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, Button } from '@mui/material';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import SelectTable, { SelectColumnType } from 'components/Table/SelectTable';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
// import Chip from 'ui-component/extended/Chip';
import Chip from '@mui/material/Chip';
import { SnackBarType } from 'types/common';
import dayjs, { Dayjs } from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

type OverViewReportPropsType = {
    loading: boolean;
    setLoading: (value: boolean) => void;
    batches: any;
    setBatches?: (value: any) => void;
    batchID: number;
    setBatchID: (value: number) => void;
    snackbar: SnackBarType;
    setSnackbar: (value: SnackBarType) => void;
};

export default function FullReport({
    loading,
    setLoading,
    batches,
    setBatches,
    batchID,
    setBatchID,
    snackbar,
    setSnackbar
}: OverViewReportPropsType) {
    const [students, setStudents] = useState<any[]>([]);
    const [dateValue, setDateValue] = useState<DateRange<Dayjs>>([null, null]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [checkAll, setCheckAll] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
    const [columns, setColumns] = useState<SelectColumnType[]>([
        {
            header: langString('name'),
            accessor: 'name'
        },
        {
            header: langString('email'),
            accessor: 'email'
        }
    ]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    const selectAllStudents = (value: boolean) => {
        if (value) {
            setCheckAll(true);
            if (students) {
                const allStudents: number[] = [];
                students.forEach((st) => allStudents.push(st.userid));
                setSelectedStudents(allStudents);
            }
        } else {
            setCheckAll(false);
            setSelectedStudents([]);
        }
    };

    const selectSingleStudent = (studentID: number) => {
        const allSelectedStudents = [...selectedStudents];
        if (allSelectedStudents.includes(studentID)) {
            const reduced = allSelectedStudents.filter((item) => item !== studentID);
            setSelectedStudents(reduced);
        } else {
            allSelectedStudents.push(studentID);
            setSelectedStudents(allSelectedStudents);
        }
    };

    const parseTableColumn = (data: any[]) => {
        if (data.length > 0) {
            const col: any = [
                {
                    header: 'Name',
                    accessor: 'name'
                },
                {
                    header: 'Email',
                    accessor: 'email'
                }
            ];
            data[0].attendenceReport.map((el: any) => {
                col.push({
                    header: dayjs.unix(el.date).format('LL'),
                    accessor: el.date
                });
            });
            setColumns(col);
        }
        return [];
    };

    const parseTableData = (data: any) => {
        if (data.length) {
            const col: any = [];
            data.map((el: any) => {
                const student: any = [];
                student.name = el.name;
                student.email = el.email;
                el.attendenceReport.map((ar: any) => {
                    student[ar.date] = ar.isPresent ? (
                        <Typography variant="h4" gutterBottom>
                            <Chip label="Present" color="success" />
                        </Typography>
                    ) : (
                        <Typography variant="h4" gutterBottom>
                            <Chip label="Absent" color="error" />
                        </Typography>
                    );
                });
                col.push(student);
            });
            setStudents(col);
        } else {
            setStudents([]);
        }
        return [];
    };

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'attendence_full_report'),
                    makeFormData([
                        { key: 'course_id', value: course?.id },
                        { key: 'batch_id', value: batchID },
                        { key: 'search', value: search },
                        { key: 'from_date', value: dateValue[0] ? dayjs(dateValue[0]).format('MM/DD/YYYY') : 0 },
                        { key: 'to_date', value: dateValue[1] ? dayjs(dateValue[1]).format('MM/DD/YYYY') : dayjs().format('MM/DD/YYYY') },
                        { key: 'rows_per_page', value: rowsPerPage },
                        { key: 'page_no', value: page }
                    ])
                );

                if (response.data.success) {
                    if (response.data.response.data) {
                        parseTableColumn(response.data.response.data);
                        parseTableData(response.data.response.data);
                        setCount(response.data.response?.totalCount);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        if (course && batchID) {
            init();
        }
    }, [course, batchID, search, rowsPerPage, page, dateValue]);

    return (
        <>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth sx={{ paddingRight: 1 }}>
                        <InputLabel id="batch-select-label">{langString('batch')}</InputLabel>
                        <Select
                            size="small"
                            name="batchs"
                            labelId="batch-select-label"
                            id="batch-select"
                            value={batchID}
                            label={langString('batch')}
                            onChange={(event: any) => setBatchID(event.target.value)}
                        >
                            {batches.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={3}>
                    <TextField
                        id="outlined-search"
                        value={search}
                        name="search"
                        size="small"
                        onChange={(event: any) => setSearch(event.target.value)}
                        label={langString('search')}
                        placeholder={langString('search')}
                    />
                </Grid>
                <Grid item sm={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} localeText={{ start: langString('from'), end: langString('to') }}>
                        <DesktopDateRangePicker
                            value={dateValue}
                            onChange={(newValue) => {
                                setDateValue(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField size="small" {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField size="small" {...endProps} />
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item sm={1}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setDateValue([null, null]);
                            setSearch('');
                        }}
                    >
                        {langString('clear')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={12}>
                    <SelectTable
                        idField="userid"
                        rows={students}
                        columns={columns}
                        showSL
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={(value: number) => setPage(value)}
                        setRowsPerPage={(value: number) => setRowsPerPage(value)}
                        selectedFields={selectedStudents}
                        checkAll={checkAll}
                        changeCheckAll={(value: boolean) => selectAllStudents(value)}
                        checkUpdate={(id: number) => selectSingleStudent(id)}
                    />
                </Grid>
            </Grid>
        </>
    );
}
