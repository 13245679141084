import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
// material-ui
import { Button, Typography, Box, Modal, IconButton, Grid } from '@mui/material';
import langString from 'utils/langString';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import CloseIcon from '@mui/icons-material/Close';
import FormValidators from 'utils/FormValidators';
import BatchForm from './Form';
import axiosServices from 'utils/axiosServices';
import { makeFormData, requestUrl } from 'utils/Helpers';
import { SnackBarType } from 'types/common';
import { AxiosError } from 'axios';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
};

type AddBatchPropsType = {
    refreshList: () => void;
    setSnackbar: (value: SnackBarType) => void;
};
export default function AddBatch({ refreshList, setSnackbar }: AddBatchPropsType) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [enrollKey, setEnrollKey] = useState('');
    const [capacity, setCapacity] = useState(0);
    const [schedules, setSchedules] = useState<string[]>(['Sunday']);
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    const saveData = async () => {
        if (course) {
            setIsSubmitting(true);
            const allErrors = FormValidators(
                ['name', 'schedules', 'capacity'],
                [name, schedules, capacity],
                ['string', 'string[]', 'number']
            );
            if (allErrors.valid) {
                try {
                    const response = await axiosServices.post(
                        requestUrl('ace_exam', 'add_batch'),
                        makeFormData([
                            { key: 'course_id', value: course?.id },
                            { key: 'name', value: name },
                            { key: 'enrolment_key', value: enrollKey },
                            { key: 'max_students', value: capacity },
                            { key: 'schedules', value: schedules.filter((item) => item !== 'select') }
                        ])
                    );
                    if (response.data.success) {
                        refreshList();
                        setName('');
                        setEnrollKey('');
                        setCapacity(0);
                        setSchedules(['Sunday']);
                    } else {
                        setSnackbar({ open: true, message: response.data.msg });
                    }
                } catch (e) {
                    const error = e as AxiosError;
                    setSnackbar({ open: true, message: error.response?.data.msg });
                }
                setOpen(false);
            } else {
                setErrors(allErrors);
            }
            setIsSubmitting(false);
        } else {
            setSnackbar({ open: true, message: 'Course not selected' });
        }
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={() => setOpen(true)} startIcon={<AddCircleOutlineRoundedIcon />}>
                {langString('add')} {langString('batch')}
            </Button>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ borderBottom: '1px solid #000', marginBottom: 3 }}
                    >
                        <Grid item xs={6}>
                            <Typography variant="h4">
                                {langString('add')} {langString('batch')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <IconButton
                                color="secondary"
                                onClick={() => setOpen(false)}
                                aria-label="closeModal"
                                component="span"
                                sx={{ color: 'black', float: 'right' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={12}>
                            <BatchForm
                                name={name}
                                setName={(value: string) => setName(value)}
                                enrollKey={enrollKey}
                                setEnrollKey={(value: string) => setEnrollKey(value)}
                                capacity={capacity}
                                setCapacity={(value: number) => setCapacity(value)}
                                schedules={schedules}
                                setSchedules={(values: string[]) => setSchedules(values)}
                                errors={errors}
                                submitting={isSubmitting}
                                saveData={() => saveData()}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}
