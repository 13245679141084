/* eslint-disable react/no-danger */
import { Box, Grid, Typography } from '@mui/material';
import { MathJax } from 'better-react-mathjax';

type HtmlRenderTypes = {
    content: string;
    numbering?: string | number;
    concatableString?: string | number;
};
const ContentViewer = ({ content, numbering = '', concatableString = '' }: HtmlRenderTypes) => (
    <Box sx={{ display: 'block' }}>
        <MathJax hideUntilTypeset="first" dynamic={false}>
            <>
                <Grid container spacing={2}>
                    {numbering && (
                        <Grid item>
                            <Typography variant="h4">{numbering}</Typography>
                        </Grid>
                    )}
                    <Grid item paddingLeft={2}>
                        <div
                            style={{ display: 'flex' }}
                            dangerouslySetInnerHTML={{ __html: `${concatableString} <span>${content}</span>` }}
                        />
                    </Grid>
                </Grid>
            </>
        </MathJax>
    </Box>
);
export default ContentViewer;
