import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, FormControl, MenuItem, TextField } from '@mui/material';
import { DefaultRootStateProps } from 'types';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { removeCourseInfo, setCourseInfo, requestUrl, makeFormData } from 'utils/Helpers';
import { COURSE_REOMVE, COURSE_SELECT } from 'store/actions';
import { CourseCategoryType } from 'types/course';

export default function SelectedCourse() {
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const [allCourses, setAllCourses] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);
    const [topics, setTopics] = useState<string[]>([]);
    const [difficulties, setDifficulties] = useState<string[]>([]);
    const [batches, setBatches] = useState<string[]>([]);
    const dispatchCourseInfo = useDispatch();

    const getTopics = async (courID: any, catID: any) => {
        if (courID && catID) {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_topics'),
                    makeFormData([{ key: 'course_id', value: courID }])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setTopics(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    };
    const getBatches = async (courID: any, catID: any) => {
        if (courID && catID) {
            try {
                const response = await axiosServices.post(
                    requestUrl('ace_exam', 'get_batches'),
                    makeFormData([{ key: 'course_id', value: courID }])
                );
                if (response.status === 200 && response.data.success) {
                    if (response.data?.response) {
                        setBatches(response.data.response);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    };
    const getDifficulties = async () => {
        try {
            const response = await axiosServices.post(requestUrl('ace_exam', 'get_dificulties'));
            if (response.status === 200 && response.data.success) {
                if (response.data?.response) {
                    setDifficulties(response.data.response);
                }
            }
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        const coursID = course.id;
        const cateID = course.categoryID;
        getTopics(coursID, cateID);
        getDifficulties();
        getBatches(coursID, cateID);
    }, [course.id, course.categoryID]);

    useEffect(() => {
        const courseID = window.localStorage.getItem('courseID');
        const categoryID = window.localStorage.getItem('categoryID');
        if (courseID && categoryID) {
            const courseName = window.localStorage.getItem('courseName');
            const categoryName = window.localStorage.getItem('categoryName');
            dispatchCourseInfo({
                type: COURSE_SELECT,
                course: {
                    id: courseID,
                    name: courseName || '',
                    categoryID,
                    categoryName: categoryName || '',
                    topics: topics || [],
                    difficulties: difficulties || [],
                    batches: batches || []
                }
            });
        } else {
            dispatchCourseInfo({
                type: COURSE_REOMVE,
                course: null
            });
        }
    }, [difficulties, batches, dispatchCourseInfo, topics]);

    useEffect(() => {
        const init = async () => {
            try {
                const response = await axiosServices.get(requestUrl('ace_exam', 'teacher_courses'));
                if (response.data.success) {
                    if (response.data?.response?.length) {
                        const teachersCourses = response.data.response;
                        setAllCourses(teachersCourses);
                        const uniqueCategories = getUniqueCategories(teachersCourses);
                        setCategories(uniqueCategories);
                        let courseID = window.localStorage.getItem('courseID') || '';
                        let categoryID = window.localStorage.getItem('categoryID') || '';
                        let courseName = window.localStorage.getItem('courseName') || '';
                        let categoryName = window.localStorage.getItem('categoryName') || '';
                        if (!categoryID) {
                            categoryID = uniqueCategories[0].categoryID?.toString() || '';
                            categoryName = uniqueCategories[0].categoryName?.toString() || '';
                        }
                        const catCourse = teachersCourses.find((item: any) => item.category_id === categoryID);
                        if (!courseID) {
                            courseID = catCourse.id.toString() || '';
                            courseName = catCourse.course_name?.toString() || '';
                        }
                        setCourseInfo(courseID, courseName, categoryID, categoryName);
                        dispatchCourseInfo({
                            type: COURSE_SELECT,
                            course: {
                                id: courseID,
                                name: courseName,
                                categoryID,
                                categoryName,
                                batches: batches || []
                            }
                        });
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        init();
    }, [batches, dispatchCourseInfo]);

    const getUniqueCategories = (courseList: any[]) => {
        const uniqueCategotyIDs: number[] = [];
        const uniqueCategories: CourseCategoryType[] = [];
        courseList.forEach((element: any) => {
            if (!uniqueCategotyIDs.includes(element.category_id)) {
                uniqueCategotyIDs.push(element.category_id);
                uniqueCategories.push({ categoryID: element.category_id, categoryName: element.category_name });
            }
        });
        return uniqueCategories;
    };

    const changeCourseCategory = (id: number) => {
        if (id) {
            const catCourse = allCourses.find((item: any) => item.category_id === id);
            if (catCourse) {
                setCourseInfo(
                    catCourse.id.toString(),
                    catCourse.course_name.toString(),
                    catCourse.category_id.toString(),
                    catCourse.category_name.toString()
                );
                dispatchCourseInfo({
                    type: COURSE_SELECT,
                    course: {
                        id: catCourse.id,
                        name: catCourse.course_name,
                        categoryID: catCourse.category_id,
                        categoryName: catCourse.category_name,
                        topics: topics || [],
                        difficulties: difficulties || [],
                        batches: batches || []
                    }
                });
            }
        } else {
            removeCourseInfo();
        }
    };

    const selectCourse = (id: number) => {
        if (id) {
            const courseInfo = allCourses.find((item: any) => item.id === id);
            if (courseInfo) {
                setCourseInfo(
                    id.toString(),
                    courseInfo?.course_name.toString(),
                    courseInfo?.category_id.toString(),
                    courseInfo?.category_name.toString()
                );
                dispatchCourseInfo({
                    type: COURSE_SELECT,
                    course: {
                        id,
                        name: courseInfo?.course_name,
                        categoryID: courseInfo?.category_id,
                        categoryName: courseInfo?.category_name,
                        topics: topics || [],
                        difficulties: difficulties || [],
                        batches: batches || []
                    }
                });
            }
        } else {
            removeCourseInfo();
        }
    };

    return (
        <>
            <Box component="span" sx={{ marginLeft: 3 }}>
                <FormControl fullWidth>
                    <TextField
                        select
                        size="small"
                        id="outlined-basic"
                        label={langString('category')}
                        variant="outlined"
                        value={course?.categoryID || 0}
                        onChange={(event: any) => changeCourseCategory(event.target.value)}
                        sx={{ minWidth: 150 }}
                    >
                        <MenuItem value={0}>
                            {langString('select')} {langString('category')}
                        </MenuItem>
                        {categories &&
                            categories.map((item) => (
                                <MenuItem key={item.categoryID} value={item.categoryID}>
                                    {item.categoryName}
                                </MenuItem>
                            ))}
                    </TextField>
                </FormControl>
            </Box>
            <Box component="span" sx={{ marginLeft: 3 }}>
                <FormControl fullWidth>
                    <TextField
                        select
                        size="small"
                        name="course"
                        id="course-select"
                        label={langString('course')}
                        variant="outlined"
                        value={course ? course.id : 0}
                        onChange={(event: any) => selectCourse(event.target.value)}
                        sx={{ minWidth: 150 }}
                    >
                        <MenuItem value={0}>
                            {langString('select')} {langString('course')}
                        </MenuItem>
                        {allCourses &&
                            allCourses.map(
                                (item: any) =>
                                    item.category_id === course?.categoryID && (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.course_name}
                                        </MenuItem>
                                    )
                            )}
                    </TextField>
                </FormControl>
            </Box>
        </>
    );
}
