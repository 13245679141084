import { useEffect, useState } from 'react';
import { Button, Grid, IconButton, Input, Tooltip } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import Wrapper from 'components/Card/Wrapper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import langString from 'utils/langString';
import axiosServices from 'utils/axiosServices';
import { SnackBarType } from 'types/common';
import { makeFormData, requestUrl } from 'utils/Helpers';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultRootStateProps } from 'types';
import SearchBox from 'components/common/SearchBox';
import AddBatch from './Manage/Add';
import EditBatch from './Manage/Edit';
import ConfirmButton from 'components/common/ConfirmButton';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import HailIcon from '@mui/icons-material/Hail';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { COURSE_SELECT } from 'store/actions';
import DataGridTable from 'components/Table/DataGridTable';
import { GridColDef } from '@mui/x-data-grid';

export default function BatchList() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [batches, setBatches] = useState<any[]>([]);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);
    const navigate = useNavigate();
    const dispatchCourseInfo = useDispatch();

    const batchName = (batchID: number) => {
        if (batches) {
            const batchFound = batches.find((item) => item.id === batchID);
            if (batchFound) {
                return batchFound.name;
            }
        }
        return '';
    };

    // const rename = (name: any) => {
    //     const words = name.split(' ');
    //     const newArray = [];

    //     for (let i = 0; i < words.length; i += 3) {
    //         const chunk = words.slice(i, i + 3);
    //         const chunkString = chunk.join(' ');
    //         newArray.push(chunkString);
    //     }
    //     return newArray;
    // };

    const BatchDataGridColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: langString('name'),
            flex: 3,
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: true,
            disableColumnMenu: true,
            renderCell: (params: any) => <Input value={params.row.name} multiline disableUnderline style={{ textAlignLast: 'center' }} />
        },
        {
            field: 'schedules',
            headerName: langString('schedules'),
            flex: 4,
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params: any) => (
                <div style={{ lineHeight: 'normal' }}>
                    {params.row.schedules.map((param: any) => (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>{param}</div>
                    ))}
                </div>
            )
        },
        {
            field: 'max_students',
            headerName: langString('studentcapacity'),
            flex: 2,
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: true,
            disableColumnMenu: true
        },
        {
            field: 'enrolled_students',
            headerName: langString('studentenrolled'),
            flex: 2,
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: true,
            disableColumnMenu: true
        },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (item: any) => (
                // for each button direction to column and to entry new button, buttons have to be divided per line. here 2 buttons are taken by div
                <div style={{ lineHeight: 'normal', display: 'flex', flexDirection: 'column' }}>
                    <div>
                        <IconButton onClick={() => navigate(`/batch-students/${item.id}`)}>
                            <Tooltip title={langString('view')}>
                                <VisibilityIcon />
                            </Tooltip>
                        </IconButton>
                        <ConfirmButton
                            color="error"
                            icon={<DeleteForeverIcon />}
                            subTitle={`Delete Batch ${batchName(item.id)}`}
                            confirmed={() => deleteBatch(item.id)}
                        />
                    </div>
                    <EditBatch
                        batchID={item.id}
                        batchName={item.name}
                        batchKey={item.enrolmentkey}
                        batchCapacity={item.max_students}
                        batchSchedules={item.schedules?.length ? item.schedules : ['Sunday']}
                        refreshList={() => refreshList()}
                        setSnackbar={(value: SnackBarType) => setSnackbar(value)}
                    />
                </div>
            )
        }
    ];

    const refreshList = async () => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'get_batches'),
                makeFormData([{ key: 'course_id', value: course?.id }])
            );
            if (response.data.success) {
                if (response.data?.response) {
                    setBatches(response.data.response);
                    dispatchCourseInfo({
                        type: COURSE_SELECT,
                        course: {
                            id: course.id,
                            name: course.name,
                            categoryID: course.categoryID,
                            categoryName: course.categoryName,
                            batches: response.data?.response || []
                        }
                    });
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    const deleteBatch = async (id: number) => {
        setLoading(true);
        try {
            const response = await axiosServices.post(
                requestUrl('ace_exam', 'delete_batch'),
                makeFormData([{ key: 'batch_id', value: id }])
            );
            if (response.data.success) {
                if (response.data?.response) {
                    refreshList();
                }
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSnackbar({ open: true, message: langString('servererror') });
        }
    };

    useEffect(() => {
        refreshList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (course.id && course.batches) {
            setBatches(course.batches);
            if (!batches) {
                setSnackbar({ open: true, message: langString('nobatchavailable') });
            }
        }
    }, [course.id, course.batches, batches]);
    console.log(batches);

    return (
        <Wrapper
            title={langString('batch')}
            icon={<GroupIcon />}
            snakbar={snackbar}
            snackbarClose={() => setSnackbar({ open: false, message: '' })}
            loading={loading}
        >
            <Grid container sx={{ marginBottom: 2 }}>
                <Grid item sm={6}>
                    <SearchBox
                        placeholder={`${langString('search')} ${langString('batch')}`}
                        properties={['name']}
                        itemList={batches}
                        setItemList={(values) => setBatches(values)}
                        refreshList={refreshList}
                    />
                </Grid>
                <Grid item sm={6} sx={{ textAlign: 'right' }}>
                    <Grid container justifyContent="flex-end" spacing={2}>
                        <Grid item>
                            <Button variant="contained" component={RouterLink} to="/attendence" startIcon={<HailIcon />} color="success">
                                {langString('attendence')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                component={RouterLink}
                                to="/attendence-report"
                                startIcon={<LocalLibraryIcon />}
                                color="success"
                            >
                                {langString('attendencereport')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <AddBatch refreshList={() => refreshList()} setSnackbar={(value: SnackBarType) => setSnackbar(value)} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!loading && (
                <Grid container>
                    <Grid item sm={12}>
                        <DataGridTable rows={batches} columns={BatchDataGridColumns} checkAll={false} loading={false} />
                    </Grid>
                </Grid>
            )}
        </Wrapper>
    );
}
