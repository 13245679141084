import { Box, Card, CardMedia, Grid, Typography } from '@mui/material';
import { randomColor } from 'utils/Helpers';
import langString from 'utils/langString';
import { useDispatch } from 'react-redux';
import { COURSE_SELECT } from 'store/actions';

type DashboardCoursesPropsType = {
    selectedID: number | string;
    courses: any[];
};
export default function DashboardCourses({ selectedID, courses }: DashboardCoursesPropsType) {
    const dispatchCourseInfo = useDispatch();
    const selectCourse = (id: number) => {
        if (id) {
            const courseInfo = courses.find((item: any) => item.id === id);
            if (courseInfo) {
                dispatchCourseInfo({
                    type: COURSE_SELECT,
                    course: {
                        id,
                        name: courseInfo?.course_name,
                        categoryID: courseInfo?.category_id,
                        categoryName: courseInfo?.category_name
                    }
                });
                localStorage.setItem('courseID', courseInfo?.id);
                localStorage.setItem('categoryID', courseInfo?.category_id);
                localStorage.setItem('courseName', courseInfo?.course_name);
                localStorage.setItem('categoryName', courseInfo?.category_name);
            }
        }
    };

    return (
        <Box>
            <Typography variant="h5" sx={{ marginBottom: 1 }}>
                {langString('selectcourse')}
            </Typography>
            <Grid container spacing={2}>
                {courses && courses.length ? (
                    courses.map((courseItem: any) => (
                        <Grid item sm={3} key={courseItem.id}>
                            <Card
                                variant="outlined"
                                sx={{
                                    padding: 1,
                                    cursor: 'pointer',
                                    background: selectedID === courseItem.id ? '#006266' : '#FFF',
                                    boxShadow: 1
                                }}
                                onClick={() => selectCourse(courseItem.id)}
                            >
                                <Box
                                    sx={{
                                        background: courseItem.coursepic ? '#EEE' : randomColor,
                                        width: '40%',
                                        height: '30%',
                                        float: 'left',
                                        borderRadius: '15px',
                                        padding: '7px',
                                        boxShadow: 2
                                    }}
                                >
                                    {/* {courseItem.coursepic && <img src={courseItem.coursepic} alt="coursepic" style={{ width: '100%' }} />} */}
                                    <CardMedia
                                        component="img"
                                        sx={{ width: '100%', height: '100%' }}
                                        image={courseItem.coursepic}
                                        alt="coursepic"
                                    />
                                </Box>
                                <Box sx={{ width: '60%', float: 'left' }}>
                                    <Typography variant="h4" sx={{ margin: '10px', color: selectedID === courseItem.id ? '#FFF' : '#000' }}>
                                        {courseItem.course_name.length > 15
                                            ? `${courseItem.course_name.substring(0, 16)}...`
                                            : courseItem.course_name}
                                    </Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))
                ) : (
                    <Typography variant="h3" sx={{ textAlign: 'center', margin: 3, color: '#F79F1F' }}>
                        {langString('coursenotfound')}
                    </Typography>
                )}
            </Grid>
        </Box>
    );
}
