import { useState, useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { SnackBarType } from 'types/common';
import OverviewReport from './Overview';
import Wrapper from 'components/Card/Wrapper';
import langString from 'utils/langString';
import { DefaultRootStateProps } from 'types';
import { useSelector } from 'react-redux';
import ReportIcon from '@mui/icons-material/Report';
import FullReport from './FullReport';

export default function AttendenceReport() {
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState<SnackBarType>({ open: false, message: '' });
    const [batches, setBatches] = useState<any[]>([]);
    const [batchID, setBatchID] = useState(0);
    const [reportType, setReportType] = useState(1);
    const course = useSelector((state: DefaultRootStateProps) => state.courseData);

    useEffect(() => {
        if (course && course.batches) {
            setBatches(course.batches);
            if (batches) {
                setBatchID(batches[0]?.id);
            } else {
                setSnackbar({ open: true, message: langString('nobatchavailable') });
            }
        }
    }, [course, batches]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Wrapper
                    title={langString('attendencereport')}
                    icon={<ReportIcon />}
                    snakbar={snackbar}
                    snackbarClose={() => setSnackbar({ open: false, message: '' })}
                    loading={loading}
                >
                    <Grid spacing={10} container justifyContent="center" alignItems="center" marginBottom={3}>
                        <Grid item>
                            <Button
                                variant={reportType === 1 ? 'contained' : 'outlined'}
                                onClick={() => setReportType(1)}
                                sx={{ minWidth: '200px' }}
                            >
                                {langString('overview')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={reportType === 2 ? 'contained' : 'outlined'}
                                onClick={() => setReportType(2)}
                                sx={{ minWidth: '200px' }}
                            >
                                {langString('fullreport')}
                            </Button>
                        </Grid>
                    </Grid>
                    {reportType === 1 ? (
                        <OverviewReport
                            loading={loading}
                            setLoading={(value: boolean) => setLoading(value)}
                            snackbar={snackbar}
                            setSnackbar={(value: SnackBarType) => setSnackbar(value)}
                            batches={batches}
                            batchID={batchID}
                            setBatchID={(value: any) => setBatchID(value)}
                        />
                    ) : (
                        <FullReport
                            loading={loading}
                            setLoading={(value: boolean) => setLoading(value)}
                            snackbar={snackbar}
                            setSnackbar={(value: SnackBarType) => setSnackbar(value)}
                            batches={batches}
                            batchID={batchID}
                            setBatchID={(value: any) => setBatchID(value)}
                        />
                    )}
                </Wrapper>
            </Grid>
            {/* <Grid item>
                <DateTimePicker />
            </Grid> */}
        </Grid>
    );
}
